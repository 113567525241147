import React from 'react'

function Nodata(props) {
  return (
    <div
				style={{
					height: "520px",
					border: "0",
					borderLeft: "1px solid #f8f8f8",
					borderBottom: "1px solid #f8f8f8",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<i> {props.message}</i>{" "}
			</div> 
  )
}

export default Nodata