import { Select, MenuItem } from "@mui/material";
import * as React from "react";

const SelectDropdown = (props) => {
  const dropValue = props.options != undefined ? props.options : null;

  const [select, setSelect] = React.useState(
    props.value != "" ? props.value : ""
  );

  const handleChange = (e) => {
    setSelect(e.target.value);
    props.setValue(e.target.value);

  };
  React.useEffect(() => {
    if ( dropValue != undefined && dropValue != null) {
          setSelect(props.value);
    }

  }, [props.value]);
  return (

    <div className="multiselectdropdown" style={props.style}>
      <Select
        disabled={(props.isEditableUserRole ? false : true)}
        name="reason"
        style={{ width: "100%" }}
        value={select}
        onChange={(e) =>
          handleChange(
            e)
        }
      >
        {dropValue != undefined && dropValue != null
          && dropValue.map((option, index) => (
            <MenuItem key={index} value={option.Name}>
              {option.Name}
            </MenuItem>
          ))}
      </Select>

    </div>
  );
};

export default SelectDropdown;
