import * as React from 'react';

const ErrorModal=(props)=>{

   
     return ( <div className="modal" style={{visibility:(props.show)?"visible":"hidden"}}>
     <div className='modal-content'>
         <div className='modal-body'>
            {props.message}
         </div>
         <div className='modal-footer'>
             <button className="button cancel" onClick={(e)=>props.showErrorModal(props.message)} style={{marginRight:"1rem"}}>Ok</button>
         </div>
     </div>
 </div>)
 }

 export default React.memo(ErrorModal);