import * as React from 'react';
import {Input,IconButton,InputAdornment} from '@mui/material';
import { Tooltip } from '@mui/material';
 import pfContext from "../../context/PfContext";
import tooltipsContent from '../../GlobalComponent/NavBar/tooltips.json';


const TextFieldEditor=(props)=>{
 const context=React.useContext(pfContext);
  const selectElement=React.useRef();
  const [name,setName]=React.useState(props.scenario);
 
 
  const handleChange=(e)=>{
   setName(e.target.value);
    props.setScenario(e.target.value);
  }
  
  const focusInput = () => {
    selectElement.current.focus();
    selectElement.current.style.backgroundColor="#ffffff";
    selectElement.current.style.boxShadow="0px 2px 12px rgba(0, 0, 0, 0.12)";
    selectElement.current.style.borderRadius=".4rem";
  };

  // React.useEffect(()=>{
  
  //     setName(props.scenario)
    

  // },[])

  React.useEffect(()=>{
   // if(props.scenario!=="")
    {
      console.log("props.scenario",props.scenario);
      setName(props.scenario)
    }

  },[props.scenario])

  // const showErrorModal1 = React.useCallback(
	// 	(errMsg) => {
	// 		// debugger
	// 		setErrMsg(errMsg);

	// 		SetShowError((prev) => !prev);
	// 	},
	// 	[showError]
	// );


  // React.useEffect(()=>{
   
  //  // console.log("UseEffect called")
  //   if(props.isLoadSubmit!="")
  //   {
  //     console.log("UseEffect called"+props.isLoadSubmit)
      
  //     setName(props.scenario);
  //   }
  //   else if(props.isLoadSubmit=="" || props.isLoadSubmit==undefined)
  //  {
  //   if(props.gsm!="" && props.brand!="")
  //   {
  //     setName(props.gsm+"_"+props.brand+"_"+defaultValue);
  //     props.setScenario(props.gsm+"_"+props.brand+"_"+defaultValue);
  //     setIsDisplay(false);
  //   }
  //   else if(props.gsm!="" && props.brand=="")
  //   {
  //     setName(props.gsm+"_"+defaultValue);
  //     props.setScenario(props.gsm+"_"+defaultValue);
  //     setIsDisplay(false);
  //   }
  //   else
  //   {
  //     setName("");
  //   }
  // }
  // },[props.gsm,props.brand,props.isLoadSubmit,props.scenario])


 
  const changeScenarioName=(e)=>{
    setName(e.target.value);
    props.setScenario(e.target.value);
     // props.setScenario(e.target.value);
      document.getElementById("secnarioName").disabled=true;
      selectElement.current.style.boxShadow="none";
    selectElement.current.style.borderRadius="0";
  }
 
  const textEditorStyle=()=>({
      height:'4.2rem!important',
      fontFamily:"Lato-Medium",
      fontSize:"3rem",
      lineHeight:"4.2rem",
      color:"#555555"

  })
  const makeTextFieldEditable=(e)=>{
      document.getElementById("secnarioName").disabled=false;
      focusInput();
  }


  // const deleteData1 = () => {
	
  //   console.log("userGsm"+userGsm);
  //   console.log("props.gsm"+props.gsm);
  //   let GSm1=props.gsm.split("-");
	// 		if (
	// 			role &&
	// 			((userGsm === GSm1[0] && role.toLowerCase().includes("e2e planner")) ||
	// 				role.toLowerCase() == "admin" ||
	// 				role.toLowerCase() == "gsm")
	// 		){

			
	// 		const secnarioName1 = document.getElementById("secnarioName").value;
	// 		if(secnarioName1!=""){
	// 		const deleteQuery=`mutation MyMutation {
	// 			 Delete_Scenario(scenario_name: "${secnarioName1}", CWID:"${cwid}")
	// 			}`
	// 		//const normData_Query = getRefreshNormsQuery(selectedFilters, gsm, bran, co, loc, pro, dataForSaving,cwid);
	// 		//(true);
  //     setLoader(true);
	// 		const getdata = async () => {
	// 			try {
	// 				const response = await axios({
	// 					url: endpoint,
	// 					method: "POST",
	// 					headers: { "x-api-key": key },
	// 					data: {
	// 						query: deleteQuery,
	// 					},
	// 				}).then((response) => {
	// 					if (response.data != undefined && response.data.data != undefined) {
  //             props.setChangedStep("MonthYear");
  //             props.setSteps(["MonthYear"])
  //             props.setSelectedMonthYear(props.currentYearForPF1);
  //                 props.setIsLoadSubmit("");
  //                 props.setTableName("");
  //                 props.setGsm("");
  //                 props.setBrand("");
  //                 setName("");
  //                 props.setCom("");
  //                 props.setLocation("");
  //                 props.setProd("");
  //                 props.setIsDropDownChange("");
                 
  //                 props.setScenario("");
                 
  //                 setLoader(false);
	// 					}
	// 				});
	// 			} catch (error) {
  //         setLoader(false);
	// 				//changeLoaderState(false);
	// 			}
	// 		};

	// 		getdata();
	// 	}
	// 	else {
	// 		showErrorModal1("Scenario Name can not be blank");
	// 	}
	// }
	// else {
	// 	showErrorModal1(
	// 		`You are not allowed to Save and Edit Scenario under selected GSM. Please select the assigned GSM to save Scenario.`
	// 	);
	// }


	// };



    return(<><Input type="text" ref={selectElement}  className='textField' id="secnarioName"
            value={name}
            autoComplete="off"
            placeholder="Please Add a Scenario Name"
            onChange={handleChange}
            style={textEditorStyle()}
             onBlur={changeScenarioName} 
             disabled={true}
            endAdornment={
              <InputAdornment position="end">
                <Tooltip 
                 title={
                  <span style={{ fontSize: '16px' }}>
                    {tooltipsContent.Edit}
                  </span>
                  }
                 open={context.showTooltips && context.buttonHoverStates==="Edit"}>
                <IconButton onClick={(e)=>makeTextFieldEditable(e)}
                onMouseEnter={() => context.setButtonHoverStates(("Edit"))}
                onMouseLeave={() => context.setButtonHoverStates((""))}>
                      <img src='../../AppAssets/editTextIcon.png' alt="" style={{height:'2.2rem',width:"2.4rem"}}/>
                    </IconButton>
                </Tooltip>
                  
              </InputAdornment>
            }
          />
          </>
    );

}

export default TextFieldEditor;