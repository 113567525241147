import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: '111111',
        color: '#fff',
        display:"flex",
        flexDirection:"column",
        background: 'rgba(0,0,0,0.2)',
        '& img': {
            width: '18rem',
            '@media(max-width:769px)': {
                width: '16rem',
            },
        },
    },
}));

const Loader = () => {
    const classes = useStyles();
    //console.log("loadr")
    return (
        <Backdrop className={classes.backdrop} open>
            <img src="../../AppAssets/loader.png" alt="loader" />
            <p style={{color:"#000000",marginTop:"1rem"}}>Please wait, we’re updating the data</p>
        </Backdrop>
    );
};

export default React.memo(Loader);
