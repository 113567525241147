import React, { Component, useMemo } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Tooltip from "@mui/material/Tooltip"; 
import pfContext from "../../context/PfContext";
import tooltipsContent from '../../GlobalComponent/NavBar/tooltips.json';

const getItems = (filterValues,count, offset = 0) =>
filterValues.map(k => ({
    id: `item-${k + offset}`,
    content: k
  }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  
  const result = Array.from(list);
 // console.log("lis"+list)
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {

  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;
  return result;

};

const grid = 5;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: '9px',
  margin: `0 0 0 ${grid}px`,
  width:'142px',
  height:"30px",
  color:"#ffffff",
  fontFamily:'Lato-SemiBold',
  fontSize:'1.2rem',
  // change background colour if dragging
  background: isDragging ? "#C8DEEF" : "#1F7DEB",
  borderRadius: "6px",
  display:"flex",
  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = (isDraggingOver, isEmpty) => ({
  
  background: isEmpty? "#ffffff" :"#EBF4FB",
  display: "flex",
  padding: "10px",
  minHeight: "72px",
border: isEmpty? "none":"2px dashed #1F7DEB",
borderRadius: "16px",
minWidth:isEmpty? "200px":"auto",
alignItems:"center"
  

});

const outerDivStyle=()=>({
  width: "172px",
height: "40px",
background:"#C8DEEF",
borderRadius: "9px",
padding: "5px",
margin:"0 4px",
display:"flex",
alignItems:"center",

})

const indexNumberStyle=()=>({
margin:"0 .5rem",
color: "#1F7DEB"

})



class DraggbleList extends Component {
  static contextType =pfContext;
  constructor(props) {
    super(props);
    this.state = {
      items: getItems(props.selectedFilters,5),
      bottom: (props.secondList.length>0)?getItems(props.secondList,props.secondList.length):Array(),
      isTooltipVisible: false
      
    };
    this.onDragEnd = this.onDragEnd.bind(this);
   // this.addText = this.addText.bind(this);
  }

  /**
   * A semi-generic way to handle multiple lists. Matches
   * the IDs of the droppable container to the names of the
   * source arrays stored in the state.
   */
  id2List = {
    droppable: "items",
    droppable3: "bottom"
  };

 handleChangeStateClick = (value) => {
    const values=value.map((item)=>{
         return item.content;
    })
    this.props.setParentNode([]);
    this.props.setSubChild1([]);
    this.props.setSubChild2([]);
    this.props.setSubChild3([]);
    this.props.setSubChild4([]);
    this.props.setPageNumber(1);
   this.props.setSelectedFilters(values);
   this.props.setIsFilterChange(true);
   };

   handleSecondListStateClick = (value) => {
    const values=value.map((item)=>{
         return item.content;
    })
   this.props.setSecondList(values);
 
   };

  getList = id => this.state[this.id2List[id]];

  onDragEnd = result => {
    const { source, destination } = result;
  
    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      if(destination.index>0){
      const items = reorder(
        this.getList(source.droppableId),
        source.index,
        destination.index
      );

      let state = { items };
        
 //  console.log("if called");

      if (source.droppableId === "droppable3") {
        state = { bottom: items };
        this.handleSecondListStateClick(items);
      }

      this.setState(state);
      this.handleChangeStateClick(items);
    }
     // this.props.handleChangeStateClick(items);
    } else {
      const result = move(
        this.getList(source.droppableId),
        this.getList(destination.droppableId),
        source,
        destination
      );
      if (result.droppable) {
        this.setState({ items: result.droppable });
       
      this.handleChangeStateClick(result.droppable);
      }
     
      if (result.droppable3) {
        this.setState({ bottom: result.droppable3 });
        this.handleSecondListStateClick(result.droppable3);
      }
      
  }
  };

 

  componentDidUpdate(nextProps){
    if(nextProps.selectedFilters !== this.props.selectedFilters){
      this.setState({ items: getItems(this.props.selectedFilters,5) });
     
      
    }
}


  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  render() {
  
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              
              {this.state.items.map((item, index) => (
                <Draggable key={`Draggable_${item.id}`} draggableId={item.id} index={index}
                  isDragDisabled={item.content==="GSM"} 

                >
                  
                  {(provided, snapshot) => (
                    <div
                      id="itemOuterDiv"
                      style={outerDivStyle()}
                      onMouseEnter={() => this.context.setButtonHoverStates(item.content)}
                      onMouseLeave={() => this.context.setButtonHoverStates("")}
                    >
                      <p className="title_6" style={indexNumberStyle()}>
                        {(index+1)}
                      </p> 
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={item.content==="GSM"?outerDivStyle():getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <Tooltip
                          title={
                            <span style={{ fontSize: '16px' }}>
                              {tooltipsContent.BluePart}
                            </span>
                          }
                          open={this.context.showTooltips && this.context.buttonHoverStates === item.content}
                        > 
                          <span style={{ display: "flex", alignItems: "center" }}>
                            <img src="../../AppAssets/grid.png" alt="grid" style={{ marginRight: ".5rem", height: ".9rem", width: "1rem" }} /> {(item.content === "Brand") ? "Pipeline" : item.content}
                          </span>
                        </Tooltip> 
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>

        <Droppable droppableId="droppable3" direction="horizontal">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver, true)}
            >
              {this.state.bottom.map((item, index) => (
                <Draggable key={`Droppable_${item.id}`} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      {(item.content=="Brand")?"Pipeline":item.content}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        
      </DragDropContext>
    );
  }
}

export default DraggbleList;