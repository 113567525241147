import { Button, ListItemText, MenuItem } from "@mui/material";
import * as React from "react";
import { useState, useRef } from "react";
import ClickAwayListener from "@mui/base/ClickAwayListener";


function LoadPrevScenario(props) {
const [searchValue,setSearchValue]=useState("");
const [defaultValue,setDefaultValue]=useState(props.scenario);
const [isOpen, setIsOpen] = useState(false);
const selectElement = React.useRef();
const inputElement = useRef();
const [listItem, setListItem] = useState(null);


React.useEffect(()=>{
	if(props.list.length>0)
	{
		setDefaultValue("");
		if(!props.isSaveDone)
		props.setScenario("");
		setListItem(props.list)	
	}
},[props.list])

const innerDiv = () => ({
	backgroundColor: "#ffffff",
	width: "100%",
	height: "70%",
	display: "flex",
	flexDirection: "column",
	overflowY: "auto",
});
const inputDiv = () => ({
	padding: "1.4rem",
	margin: "1.6rem",
	background: "#FAFAFA",
	border: "1px solid #E6E6E6",
	borderRadius: "4px",
	width: "53.9rem",
	height: "4.4rem",
});

const tableStyle = () => ({
	position: "relative",
	height: "auto",
	padding: "0 1.6rem",
	tableLayout: "fixed",
	display: "block",
});
const tdStyle = () => ({
	textAlign: "left",
	background: "#FFFFFF",
	padding: "0.8rem 1.6rem .8rem 0px",
});
const trStyle = () => ({
	background: "#FFFFFF",
	height: "2rem",
});

const thStyle = () => ({
	textAlign: "left",
	color: "#9B9B9B",
	background: "#FFFFFF",
});
const buttonDivContainer = () => ({
	background: "rgb(214, 221, 219)",
	borderTop: "1px solid rgb(211, 211, 211)",
	width: "100%",
	height: "6rem",
	position: "absolute",
	bottom: "-.1rem",
	padding: ".8rem 0",
});
const focusInput = () => {
	inputElement.current.focus();
};
const focusDropDown = () => {
    selectElement.current.focus();
    selectElement.current.style.backgroundColor = "#ffffff";
    selectElement.current.style.boxShadow = "0px 2px 12px rgba(0, 0, 0, 0.12)";
    selectElement.current.style.borderRadius = ".4rem";
};

const loadScenario = () => {
    //console.log(isOpen);
    setIsOpen(!isOpen);
    focusDropDown();
    
};

const closeLoadSecnario=()=>
	{
		setIsOpen(!isOpen);
		focusDropDown();
		//setDefaultValue("");
	}

const handleAway = (e) => {
    setIsOpen(false);
    selectElement.current.style = "";
    selectElement.current.style.width = "100%";
};

const handleRadioClick = (event) => {
	 if (event.target.checked && defaultValue!==event.target.value) 
	 setDefaultValue(event.target.value);
	else
	setDefaultValue("");
	
	
    setSearchValue("");
};



const handleClose = () => {
	setSearchValue("");
	setDefaultValue("");
	closeLoadSecnario("");
};

const handleChange = () => {
	setSearchValue("");
	closeLoadSecnario("");
};

const handleSubmit = () => {
	if(defaultValue!==""){
		props.setScenario(defaultValue);
		props.setIsLoadSubmit(true);
	}
	setSearchValue("");
		closeLoadSecnario();

};



  return (
    <ClickAwayListener onClickAway={handleAway}>
			<div className="dropdown" style={{ width: "28rem" }}>
				<Button
					onClick={(e)=>loadScenario()}
					ref={selectElement}
					className="multiselectdropdown multiselectdropdown_item"
					style={{ width: "100%" }}
				>
					{(defaultValue!=="" && defaultValue!==null)?defaultValue:"Load Previous Scenario"}
					<span style={{ position: "absolute", right: "1rem" }}>
						<img src="../../AppAssets/down.png" alt="true" />
					</span>
				</Button>
				<div className="dropdownContent" style={{ display: isOpen ? "block" : "none" }}>
					{ (
						<>
                        <input
				style={inputDiv()}
				ref={inputElement}
				type="text"
				value={searchValue}
				placeholder="Search.."
				onChange={handleChange}
				onFocus={focusDropDown}
			/>

			<div style={innerDiv()} onChange={(e) => handleRadioClick(e)}>
				<table style={tableStyle()} id="scenarioList">
					<thead>
						<tr style={trStyle()}>
							<th style={thStyle()} className="title_4">
								Name
							</th>
							{/*<th style={thStyle()} className='title_4'>Updated</th>
                        <th style={thStyle()} className='title_4'>Created By</th>*/}
						</tr>
					</thead>
					<tbody style={{ borderTop: "1px solid #9B9B9B" }}>
						{listItem !== null ? (
							listItem.map((item,index) => {
								if (item.Name !== "" && item.Name !== null) {
									return (
										<tr key={index} style={trStyle()}>
											<td style={tdStyle()} className="title_4" id={item.Name}>
												<label key={item.id} style={{ display: "flex", fontSize: "1.4rem" }}>
													<input
														readOnly
														type="checkbox"
														name="sc"
														value={item.Name}
														checked={item.Name === defaultValue ? true : false}
														style={{ marginRight: ".5rem" }}
													/>
													{item.Name}
												</label>
											</td>
										</tr>
									);
								}
							})
						) : (
							<tr>
								<td>No Data Available</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
			<div className="boxFlex" style={buttonDivContainer()}>
				<Button
					className="button cancel"
					style={{ width: "26rem", marginRight: "1.6rem", height: "4.4rem" }}
					onClick={handleClose}
				>
					Close
				</Button>
				<Button className="button save" style={{ width: "26rem", height: "4.4rem" }} onClick={handleSubmit}>
					Load Selected
				</Button>
			</div>
                        </>
					)}
				</div>
			</div>
		</ClickAwayListener>
  )
}

export default LoadPrevScenario
