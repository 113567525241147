import { Button, Select } from "@mui/material";
import * as React from "react";
import ClickAwayListener from "@mui/base/ClickAwayListener";

const SkuDropDown = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [show, setShow] = React.useState("none");
  const dropValue = props.options != undefined ? props.options : null;
  const selectElement = React.useRef();

  const [select, setSelect] = React.useState(
    props.value != "" ? props.value : props.label
  );

  const handleButtonClick = (e) => {
    setIsOpen((prev) => !prev);
    focusInput();
  };
  const handleChange = (e) => {
    setSelect(e.target.innerText);
    props.setValue(e.target.id);

    setIsOpen((prev) => !prev);
  };

  const focusInput = () => {
    selectElement.current.focus();
    selectElement.current.style.backgroundColor = "#ffffff";
    selectElement.current.style.boxShadow = "0px 2px 12px rgba(0, 0, 0, 0.12)";
    selectElement.current.style.borderRadius = ".4rem";
  };

  const handleAway = (e) => {
    setIsOpen(false);
    selectElement.current.style = "";
    selectElement.current.style.width = "100%";
  };

  React.useEffect(() => {
    if (props.value != "" && dropValue != undefined && dropValue != null) {
      const value = dropValue.map((option) => {
        if (option.code == props.value) {
          setSelect(option.Name);
          selectElement.current.innerText = option.Name;
          props.setValue(option.code);
          return option;
        }
      });
    }

    setShow(isOpen ? "block" : "none");
  }, [props.value, isOpen]);

  return (
    <ClickAwayListener onClickAway={handleAway}>
      <div className="multiselectdropdown" style={props.style}>
        <Button
          ref={selectElement}
          style={{ width: "100%" }}
          onClick={(e) => handleButtonClick(e)}
          className="multiselectdropdown multiselectdropdown_item"
          disabled={props.label==="Comment" ? (props.isEditableUserRole?false:true):false}
        >
          {select}
          <span style={{ position: "absolute", right: "1rem" }}>
            <img src="../../AppAssets/down.png" alt="true" />
          </span>
        </Button>
        <div
          style={{ display: `${show}`, height:props.label=="sortType"?"10rem":"" }}
          className="multiselectdropdown_itemContainer"
        >
          <ul
            className="multiselectdropdown_itemInnerContainer"
            style={{ listStyle: "none",width:props.label=="Comment"?"100%":"" }}
          >
            {dropValue != undefined && dropValue != null
              ? dropValue.map((option, index) => (
                  <li
                    className="multiselectdropdown_item"
                    key={index}
                    onClick={(e) => handleChange(e)}
                    id={option.code}
                  >
                    {option.Name}
                  </li>
                ))
              : ""}
          </ul>
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default SkuDropDown;
