import { Button, Checkbox } from '@mui/material';
import * as React from 'react';
import {useState,useEffect,useRef} from 'react';
import './dropdown.css';
import ClickAwayListener from '@mui/base/ClickAwayListener';

const MultiSelectDropDown=(props)=>{
  const [isOpen,setIsOpen]=useState(false);
  const [show,setShow]=useState("none");
  const dropDownItems=props.options;
  const inputElement=useRef();
  const [checkedCheckboxes, setCheckedCheckboxes] = useState(props.selectedValues);

  const focusInput = () => {
    inputElement.current.focus();
    inputElement.current.style.backgroundColor="#ffffff";
    inputElement.current.style.border="1px solid #D3D3D3";
    inputElement.current.style.boxShadow="0px 2px 12px rgba(0, 0, 0, 0.12)";
    inputElement.current.style.borderRadius=".4rem";
  };

  const checkDropDownState=(e)=>{
    setIsOpen(isOpen=>!isOpen);
    focusInput();
    
  }
  const handleClickAway = () => {
    setIsOpen(false);
    inputElement.current.style="";
  };
  useEffect(()=>{
    setShow(isOpen?"block":"none");
  },[isOpen]);
  
   const handleCheckboxChange = (data) => {
    const isChecked = checkedCheckboxes.some(checkedCheckbox => checkedCheckbox === data)
    if (isChecked) {
      setCheckedCheckboxes(
        checkedCheckboxes.filter(
          (checkedCheckbox) => checkedCheckbox !== data
        )
      );
    } else {
      setCheckedCheckboxes(checkedCheckboxes.concat(data));
    }
   
  };
 useEffect(()=>{
  if(checkedCheckboxes!==null)
  props.setSelectedValues(checkedCheckboxes);

},[checkedCheckboxes]);
  
  return(
    <ClickAwayListener onClickAway={handleClickAway}>
  <div className="multiselectdropdown">
      <Button ref={inputElement} onClick={checkDropDownState}   className="multiselectdropdown multiselectdropdown_item">{props.label}
      <span style={{position:"absolute",right:"1rem"}}><img src="../../AppAssets/down.png" alt="true"/></span>
      </Button>
      <div className="multiselectdropdown_itemContainer" style={{display:`${show}`}}>
      <div className='multiselectdropdown_itemInnerContainer'>
       
            {(dropDownItems).map((item,index)=>{
                   return( <label key={item.id} className="multiselectdropdown_item"><input type="checkbox"  
                    name="sc" value={item.id} id={`custom-checkbox-${index}`} style={{marginRight:'1rem'}} onChange={(e)=>handleCheckboxChange(item.id)}  checked={checkedCheckboxes.some(checkedCheckbox => checkedCheckbox === item.id)}  />
                   {item.name}</label>
            )})}

    </div>
      </div>
  </div>
  </ClickAwayListener>
  );

}

export default MultiSelectDropDown;