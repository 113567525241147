
export function AddMonths(date, months) {
  const current = new Date();
  current.setMonth(current.getMonth() + months);
    
    return current;
  }

  
export const current = new Date();
export const date=current.getDate();
export const month=current.getMonth()+1;
export const year=current.getFullYear();
export const endpoint = process.env.REACT_APP_ENDPOINT;
export const key=process.env.REACT_APP_API_KEY;



export function subtract6Months(date) {
  date.setMonth(date.getMonth() - 6);
  return date;
}