import { render } from 'react-dom';
import { QueryClient, QueryClientProvider } from "react-query";
import { RouterProvider } from "react-router-dom";
import * as config from "./Components/TargetSteering/config"

import { router } from "./App";
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import PfState from './context/PfState';


const client = new QueryClient();
const rootElement = document.getElementById('root');
const msalInstanceProvider = new PublicClientApplication(generateMsalConfig());


function generateMsalConfig() {
    const msalConfig = {
        auth: {
            clientId:process.env.REACT_APP_CLIENT_ID,
            authority: config.authorityUrl,
            redirectUri:process.env.REACT_APP_REDIRECT,
            postLogoutRedirectUri: process.env.REACT_APP_REDIRECT
            //redirectUri: "https://iscdev.auth.eu-central-1.amazoncognito.com/saml2/idpresponsenp",
        },
        cache: {
            cacheLocation: "sessionStorage", // This configures where your cache will be stored
            storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
        }
    };

    return msalConfig;
}


render(
	
    <QueryClientProvider client={client}>
        
    <MsalProvider instance={msalInstanceProvider}>
        <PfState>
                <RouterProvider router={router}  instance={msalInstanceProvider}/>
                </PfState>
    </MsalProvider>
        
        
            
        </QueryClientProvider>,
        rootElement
    );
