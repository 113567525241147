import React from "react";
import ReactMonthPicker from "react-month-picker";
import "react-month-picker/css/month-picker.css";
import "./normdata.css";
import usePrevious from "../../hooks/usePrevious";
import { getPFMonth } from "./GraphQueryNew";

const FinderMonth = (props) => {
  const pickerLang = {
    months: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    from: "From",
    to: "To",
  };
  const [currentYearForPF, monthForPF] = getPFMonth();

  const [singleValue, setSingleValue] = React.useState({
    year: currentYearForPF,
    month: Number(monthForPF),
  });

  function subtract6Months(date) {
    date.setMonth(date.getMonth() - 12);
    return date;
  }

  const current = new Date();
  const prevDate = subtract6Months(current);

  const prevGsm = usePrevious(props.value);
  const pickRange = React.createRef();

  const makeText = (m) => {
    if (m && m.year && m.month)
      return pickerLang.months[m.month - 1] + ". " + m.year;
    return "?";
  };

  const handleClickMonthBox = (e) => {
    pickRange.current.show();
  };

  const handleAMonthChange = (...args) => {
    let value = {
      year: args[0],
      month: args[1],
    };
    const mont =
      value.year + "" + (value.month >= 10 ? value.month : "0" + value.month);

    if (props.tableName !== "" || props.isSaveDone) {
      if (prevGsm !== mont) props.setIsMonthChangedAfterPreview(true);
    } else {
      setSingleValue({ year: value.year, month: value.month });
      props.setValue(mont);
    }

    pickRange.current.dismiss();
  };

  React.useEffect(() => {
    if (props.value !== "" && props.value !== undefined) {
      const monthYear1 = props.value.toString();
      const year1 = monthYear1.substr(0, 4);
      const month1 = monthYear1.substr(4, monthYear1.length);

      setSingleValue({ year: year1, month: month1 });
    }
  }, [props.value]);

  return (
    singleValue !== null && (
      <div className="editPicker">
        <img
          src="../../AppAssets/calander.png"
          alt="cal"
          style={{ margin: "0 .5rem 0 .5rem" }}
          onClick={handleClickMonthBox}
        />
        <ReactMonthPicker
          ref={pickRange}
          years={{
            min: { year: prevDate.getFullYear(), month: prevDate.getMonth() },
            max: { year: singleValue.year, month: singleValue.month },
          }}
          value={singleValue}
          lang={pickerLang}
          theme="light"
          onChange={handleAMonthChange}
          className="custom"
        >
          <MonthBox
            value={makeText(singleValue)}
            onClick={handleClickMonthBox}
          />
        </ReactMonthPicker>
      </div>
    )
  );
};

export const MonthBox = (props) => {
  const _handleClick = (e) => {
    props.onClick && props.onClick(e);
  };
  const value = props.value || "N/A";
  return (
    <div className="box" onClick={_handleClick}>
      <label>{value}</label>
    </div>
  );
};

export default FinderMonth;
