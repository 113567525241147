import axios from "axios";
import { date, year, month } from "../../GlobalComponent/util.js";

export const endpoint = process.env.REACT_APP_ENDPOINT;
export const key = process.env.REACT_APP_API_KEY;
//export const masterData_Query = ` { getmasterdata {gsm}}`;

export const getPFMonth = () => {
  let currentYearForPF = year;
  let monthForPF = month;
  if (date < 3) {
    if (month !== 1) {
      monthForPF = month - 2;
    } else if (month === 1) {
      monthForPF = 12;
      currentYearForPF = year - 1;
    }
  } else if (date >= 3) {
    if (month !== 1) {
      monthForPF = month - 1;
    } else if (month === 1) {
      monthForPF = 12;
      currentYearForPF = year - 1;
    }
  }
  monthForPF = monthForPF >= 10 ? monthForPF : "0" + monthForPF;
  return [currentYearForPF, monthForPF];
};
/*change the previous created scenario list in json format */
export function changeTheScenarioDataFormat(data) {
  let dataum = [];
  if (data != null) {
    for (let i = 0; i < data.length; i++) {
      dataum.push({ Name: data[i] });
    }
  }
  return dataum;
}
/*Loading the tree  */
export const getNormsQuery = (
  filtevalues1,
  gsm,
  brand,
  com,
  location,
  product,
  pageNumber,
  MonthYear,
  cwid,
  unit,
  filter_subpart_loc
) => {
  let offset = 0;
  if (pageNumber == 1) offset = 0;
  else offset = pageNumber * 10 - 10;
  const queryCol = ` TreeNodeId TreeNodeName_cleaned current_gi current_gi_qy current_gi_day gi_tobe gi_tobe_qy gi_tobe_day current_gr current_gr_qy current_gr_day gr_tobe gr_tobe_qy gr_tobe_day current_cycle current_cycle_qy current_cycle_day cycle_tobe cycle_tobe_qy cycle_tobe_day current_safety current_safety_qy current_safety_day safety_tobe  safety_tobe_qy safety_tobe_day current_price`;
  const subquery = ` Parent { ${queryCol} } No_Of_Pages Children_01 { ${queryCol} } Children_02 { ${queryCol} } Children_03 { ${queryCol} } Children_04 { ${queryCol} }}`;

  const filtevalues = getNewFilterValues(filtevalues1);



  if (gsm == null || gsm == "") {
    return `query MyQuery { NORMCustomizedView(${filter_subpart_loc}limit: 10,sort_by: "${unit.toLowerCase()}", offset:${offset},CWID:"${cwid}",MonthYear: "${MonthYear}"){
      ${subquery}
   }`;
  } else {
    if (filtevalues.length > 0) {
      let queryparam = "";

      for (let i = 1; i < filtevalues.length; i++) {
        if (filtevalues[i] == "Brand") {
          queryparam == ""
            ? (queryparam = `Brand=${brand}`)
            : (queryparam += `||Brand=${brand}`);
        } else if (filtevalues[i] == "Location") {
          queryparam == ""
            ? (queryparam = `Location=${location}`)
            : (queryparam += `||Location=${location}`);
        } else if (filtevalues[i] == "CoM") {
          queryparam == ""
            ? (queryparam = `CoM=${com}`)
            : (queryparam += `||CoM=${com}`);
        } else if (filtevalues[i] == "Product") {
          queryparam == ""
            ? (queryparam = `Product=${product}`)
            : (queryparam += `||Product=${product}`);
        }
      }

      let queryString = "";
      if (queryparam != "") {
        let values = queryparam.split("||");

        if (values.length === 1) {
          queryString = `,Children_01:"${values[0].split("=")[0]}",`;
          if (
            values[0].split("=")[1] !== "" &&
            values[0].split("=")[1] !== undefined
          )
            queryString += `, val_01:"${values[0].split("=")[1]}"`;
          queryString +=
            filtevalues[2] !== undefined
              ? `,Children_02: "${filtevalues[2]}" `
              : "";
        } else if (values.length == 2) {
          queryString = `,Children_01:"${values[0].split("=")[0]}"`;
          if (
            values[0].split("=")[1] !== "" &&
            values[0].split("=")[1] !== undefined
          ) {
            queryString += `,val_01:"${values[0].split("=")[1]
              }",Children_02: "${values[1].split("=")[0]}"`;

            queryString +=
              values[1].split("=")[1] !== "" &&
                values[1].split("=")[1] !== undefined
                ? `, val_02: "${values[1].split("=")[1]}" `
                : "";
            queryString +=
              values[1].split("=")[1] !== "" &&
                values[1].split("=")[1] !== undefined &&
                filtevalues[3] !== undefined
                ? `,Children_03: "${filtevalues[3]}" `
                : "";
          }
        } else if (values.length == 3) {
          queryString = `,Children_01:"${values[0].split("=")[0]}"`;
          if (
            values[0].split("=")[1] != "" &&
            values[0].split("=")[1] != undefined
          ) {
            queryString += `, val_01:"${values[0].split("=")[1]
              }" ,Children_02: "${values[1].split("=")[0]}"`;

            if (
              values[1].split("=")[1] != "" &&
              values[1].split("=")[1] != undefined
            )
              queryString += `, val_02: "${values[1].split("=")[1]
                }",Children_03: "${values[2].split("=")[0]}"`;
            if (
              values[2].split("=")[1] != "" &&
              values[2].split("=")[1] != undefined
            ) {
              queryString += `,val_03:"${values[2].split("=")[1]}"`;
              queryString +=
                filtevalues[4] != undefined
                  ? `,Children_04: "${filtevalues[4]}" `
                  : "";
            }
          }
        } else if (values.length == 4) {
          //console.log(values[1].split("=")[1]);
          queryString = `,Children_01:"${values[0].split("=")[0]}"`;
          if (
            values[0].split("=")[1] != null &&
            values[0].split("=")[1] != ""
          ) {
            queryString += `,val_01:"${values[0].split("=")[1]
              }",Children_02: "${values[1].split("=")[0]}"`;

            if (
              values[1].split("=")[1] != "" &&
              values[1].split("=")[1] != undefined
            ) {
              queryString += `, val_02: "${values[1].split("=")[1]
                }",Children_03: "${values[2].split("=")[0]}"`;
            }
            if (
              values[2].split("=")[1] != "" &&
              values[2].split("=")[1] != undefined
            ) {
              queryString += `,val_03:"${values[2].split("=")[1]}"`;
              queryString += `,Children_04: "${values[3].split("=")[0]}" `;
            }
          }
        }
      } else if (filtevalues[1] != undefined) {
        queryString = `,Children_01:"${filtevalues[1]}"`;
      }
      // //console.log(queryString);
      return `query MyQuery { NORMCustomizedView(${filter_subpart_loc}limit: 10, offset:${offset},sort_by: "${unit.toLowerCase()}", CWID:"${cwid}", MonthYear: "${MonthYear}", GSM:"GSM", GSM_val:"${gsm}" ${queryString}){
        ${subquery}
      }`;
    }
  }
};
/*this query is used to get the Saving Potential and Bar chart data after Preview of Scenario */
export const getNewPreviewCustomQuerybasedOnFilter = (
  filtevalues1,
  gsm,
  brand,
  com,
  location,
  product,
  pageNumber,
  MonthYear,
  cwid,
  tableName,
  filter_subpart_loc
) => {
  //console.log("PageNumber"+pageNumber)
  let offset = 0;
  if (pageNumber == 1) offset = 0;
  else offset = pageNumber * 10 - 10;

  const filtevalues = getNewFilterValues(filtevalues1);
  {
    if (filtevalues.length > 0) {
      let queryparam = "";

      for (let i = 1; i < filtevalues.length; i++) {
        if (filtevalues[i] == "Brand") {
          queryparam == ""
            ? (queryparam = `Brand=${brand != undefined ? brand : ""}`)
            : (queryparam += `||Brand=${brand != undefined ? brand : ""}`);
        } else if (filtevalues[i] == "Location") {
          queryparam == ""
            ? (queryparam = `Location=${location != undefined ? location : ""}`)
            : (queryparam += `||Location=${location != undefined ? location : ""
              }`);
        } else if (filtevalues[i] == "CoM") {
          queryparam == ""
            ? (queryparam = `CoM=${com != undefined ? com : ""}`)
            : (queryparam += `||CoM=${com != undefined ? com : ""}`);
        } else if (filtevalues[i] == "Product") {
          queryparam == ""
            ? (queryparam = `Product=${product != undefined ? product : ""}`)
            : (queryparam += `||Product=${product != undefined ? product : ""
              }`);
        }
      }

      let queryString = "";
      if (queryparam != "") {
        let values = queryparam.split("||");

        if (values.length === 1) {
          if (
            values[0].split("=")[1] !== "" &&
            values[0].split("=")[1] !== undefined
          )
            queryString += `,att_02:"${values[0].split("=")[0]}", val_02:"${values[0].split("=")[1].split("_")[0]
              }"`;
        } else if (values.length === 2) {
          if (
            values[0].split("=")[1] !== "" &&
            values[0].split("=")[1] !== undefined
          ) {
            queryString += `,att_02:"${values[0].split("=")[0]}",val_02:"${values[0].split("=")[1].split("_")[0]
              }"`;

            queryString +=
              values[1].split("=")[1] !== "" &&
                values[1].split("=")[1] !== undefined
                ? `,att_03: "${values[1].split("=")[0]}", val_03: "${values[1].split("=")[1].split("_")[0]
                }" `
                : "";
          }
        } else if (values.length === 3) {
          if (
            values[0].split("=")[1] !== "" &&
            values[0].split("=")[1] !== undefined
          ) {
            queryString += `,att_02:"${values[0].split("=")[0]}", val_02:"${values[0].split("=")[1].split("_")[0]
              }"`;

            if (
              values[1].split("=")[1] !== "" &&
              values[1].split("=")[1] !== undefined
            )
              queryString += `,att_03: "${values[1].split("=")[0]}", val_03: "${values[1].split("=")[1].split("_")[0]
                }"`;
            if (
              values[2].split("=")[1] !== "" &&
              values[2].split("=")[1] !== undefined
            ) {
              queryString += `,att_04: "${values[2].split("=")[0]}",val_04:"${values[2].split("=")[1].split("_")[0]
                }"`;
            }
          }
        } else if (values.length == 4) {
          //console.log(values[1].split("=")[1]);

          if (
            values[0].split("=")[1] !== null &&
            values[0].split("=")[1] !== ""
          ) {
            queryString += `,att_02:"${values[0].split("=")[0]}",val_02:"${values[0].split("=")[1].split("_")[0]
              }"`;

            if (
              values[1].split("=")[1] !== "" &&
              values[1].split("=")[1] !== undefined
            ) {
              queryString += `,att_03: "${values[1].split("=")[0]}", val_03: "${values[1].split("=")[1].split("_")[0]
                }"`;
            }
            if (
              values[2].split("=")[1] !== "" &&
              values[2].split("=")[1] !== undefined
            ) {
              queryString += `,att_04: "${values[2].split("=")[0]}",val_04:"${values[2].split("=")[1].split("_")[0]
                }"`;
            }
            if (
              values[3].split("=")[1] !== "" &&
              values[3].split("=")[1] !== undefined
            ) {
              //queryString += `,val_04:"${values[2].split("=")[1]}"`;
              queryString += `,att_05: "${values[3].split("=")[0]}",val_05: "${values[3].split("=")[1].split("_")[0]
                }" `;
            }
          }
        }
      }

      let newQuery = `query MyQuery {
        LOAD_Preview(${filter_subpart_loc}limit: 10, offset:${offset}, MonthYear:"${MonthYear}", CWID:"${cwid}",table_name: "${tableName}" , att_01:"GSM", val_01:"${gsm}" ${queryString}){
        Customized {
        Cycle
        Cycle_qy
        Effects
        Effects_qy
        GoodsIssued
        GoodsIssued_qy
        GoodsReceipt
        GoodsReceipt_qy
        Id
        InTransit
        InTransit_qy
        Safety
        Safety_qy
        }
        ASIS {
           Cycle
           Cycle_qy
           Effects
           Effects_qy
           GoodsIssued
           GoodsIssued_qy
           GoodsReceipt
           GoodsReceipt_qy
           Id
           InTransit
           InTransit_qy
           Price
           Safety
           Safety_qy
         }
        SP_Customized {
          Id
          GSMNetwork {
          GSMNetwork
          GSMNetwork_QY
          Potential
          Potential_QY
          Type
          }
          Brand_Location {
          Brand_Location
        Brand_Location_QY
          Potential
          Potential_QY
          Type
          }
        Location {
          Location
          Location_QY
          Potential
          Potential_QY
        Type
          }
          Product_Location {
         Potential
        Potential_QY
          Product_Location
          Product_Location_QY
        Type
        }
          SKU {
          Potential
          Potential_QY
          SKU
          SKU_QY
          Type
          }
          Brand {
          Brand
          Brand_QY
          Potential
          Potential_QY
          Type
          }
          }

        }
       }`;
      // //console.log(queryString);
      return newQuery;
    }
  }
};

function getNewFilterValues(filtevalues) {
  const filter = filtevalues.map((item) => {
    //console.log()
    if (item == "GSM") return "GSM";
    else if (item == "Pipeline") return "Brand";
    else if (item === "Material") return "Product";
    else return item;
  });
  return filter;
}

/*this query is used after clicked on preview button to load the tree  */
export const getNewPreviewCustomQuery = (
  filtevalues1,
  gsm,
  brand,
  com,
  location,
  product,
  pageNumber,
  MonthYear,
  cwid,
  tableName,
  unit,
  filter_subpart_loc
) => {
  let offset = 0;
  if (pageNumber == 1) offset = 0;
  else offset = pageNumber * 10 - 10;

  const queryCol = ` TreeNodeId user_node_cycle user_node_gi user_node_gr user_node_safety current_gi current_gi_day
  current_gi_qy gi_tobe gi_tobe_day gi_tobe_qy current_cycle current_cycle_day current_cycle_qy cycle_tobe cycle_tobe_day cycle_tobe_qy current_gr current_gr_day current_gr_qy gr_tobe gr_tobe_day gr_tobe_qy current_safety current_safety_day
  current_safety_qy safety_tobe safety_tobe_day safety_tobe_qy current_cycle_flag current_gi_flag current_gr_flag current_price current_safety_flag,zero_alert`;

  const subquery = ` Parent {${queryCol} } No_Of_Pages Children_01 {${queryCol} } Children_02 {${queryCol} } Children_03 {${queryCol}} Children_04 { ${queryCol} }}`;

  const filtevalues = getNewFilterValues(filtevalues1);
  if (filtevalues.length > 0) {
    let queryparam = "";

    for (let i = 1; i < filtevalues.length; i++) {
      if (filtevalues[i] == "Brand") {
        queryparam == ""
          ? (queryparam = `Brand=${brand != undefined ? brand : ""}`)
          : (queryparam += `||Brand=${brand != undefined ? brand : ""}`);
      } else if (filtevalues[i] == "Location") {
        queryparam == ""
          ? (queryparam = `Location=${location != undefined ? location : ""}`)
          : (queryparam += `||Location=${location != undefined ? location : ""
            }`);
      } else if (filtevalues[i] == "CoM") {
        queryparam == ""
          ? (queryparam = `CoM=${com != undefined ? com : ""}`)
          : (queryparam += `||CoM=${com != undefined ? com : ""}`);
      } else if (filtevalues[i] == "Product") {
        queryparam == ""
          ? (queryparam = `Product=${product != undefined ? product : ""}`)
          : (queryparam += `||Product=${product != undefined ? product : ""}`);
      }
    }

    let queryString = "";
    if (queryparam != "") {
      let values = queryparam.split("||");

      if (values.length == 1) {
        queryString = `,att_02:"${values[0].split("=")[0]}",`;
        if (
          values[0].split("=")[1] != "" &&
          values[0].split("=")[1] != undefined
        )
          queryString += `, val_02:"${values[0].split("=")[1].split("_")[0]}"`;
        queryString +=
          filtevalues[2] != undefined ? `,att_03: "${filtevalues[2]}" ` : "";
      } else if (values.length == 2) {
        queryString = `,att_02:"${values[0].split("=")[0]}"`;
        if (
          values[0].split("=")[1] != "" &&
          values[0].split("=")[1] != undefined
        ) {
          queryString += `,val_02:"${values[0].split("=")[1].split("_")[0]}",att_03: "${values[1].split("=")[0]
            }"`;

          queryString +=
            values[1].split("=")[1] != "" &&
              values[1].split("=")[1] != undefined
              ? `, val_03: "${values[1].split("=")[1].split("_")[0]}" `
              : "";
          queryString +=
            values[1].split("=")[1] != "" &&
              values[1].split("=")[1] != undefined &&
              filtevalues[3] != undefined
              ? `,att_04: "${filtevalues[3]}" `
              : "";
        }
      } else if (values.length == 3) {
        queryString = `,att_02:"${values[0].split("=")[0]}"`;
        if (
          values[0].split("=")[1] != "" &&
          values[0].split("=")[1] != undefined
        ) {
          queryString += `, val_02:"${values[0].split("=")[1].split("_")[0]}" ,att_03: "${values[1].split("=")[0]
            }"`;

          if (
            values[1].split("=")[1] != "" &&
            values[1].split("=")[1] != undefined
          )
            queryString += `, val_03: "${values[1].split("=")[1].split("_")[0]}",att_04: "${values[2].split("=")[0]
              }"`;
          if (
            values[2].split("=")[1] != "" &&
            values[2].split("=")[1] != undefined
          ) {
            queryString += `,val_04:"${values[2].split("=")[1].split("_")[0]}"`;
            queryString +=
              filtevalues[4] != undefined
                ? `,att_05: "${filtevalues[4]}" `
                : "";
          }
        }
      } else if (values.length == 4) {
        //console.log(values[1].split("=")[1]);
        queryString = `,att_02:"${values[0].split("=")[0]}"`;
        if (
          values[0].split("=")[1] != null &&
          values[0].split("=")[1] != "" &&
          values[0].split("=")[1] != undefined
        ) {
          queryString += `,val_02:"${values[0].split("=")[1].split("_")[0]}",att_03: "${values[1].split("=")[0]
            }"`;

          if (
            values[1].split("=")[1] != "" &&
            values[1].split("=")[1] != undefined
          ) {
            queryString += `, val_03: "${values[1].split("=")[1].split("_")[0]}",att_04: "${values[2].split("=")[0]
              }"`;
          }
          if (
            values[2].split("=")[1] != "" &&
            values[2].split("=")[1] != undefined
          ) {
            queryString += `,val_04:"${values[2].split("=")[1].split("_")[0]}"`;
            queryString += `,att_05: "${values[3].split("=")[0]}" `;
          }
          if (
            values[3].split("=")[1] != "" &&
            values[3].split("=")[1] != undefined
          ) {
            //queryString += `,val_04:"${values[2].split("=")[1]}"`;
            queryString += `,val_05: "${values[3].split("=")[1].split("_")[0]}" `;
          }
        }
      }
    } else if (filtevalues[1] != undefined) {
      queryString = `,att_01:"${filtevalues[1]}"`;
    }
    // //console.log(queryString);
    return `query MyQuery {
         LOAD_Preview(${filter_subpart_loc}limit: 10, offset:${offset},sort_by: "${unit.toLowerCase()}", MonthYear:"${MonthYear}", CWID:"${cwid}",table_name: "${tableName}" , att_01:"GSM", val_01:"${gsm}" ${queryString}){
        ${subquery}
        }`;
  }
};

export function getLoadPrevNormQuery(data, filter_subpart_loc) {
  const queryCol = ` TreeNodeId user_node_cycle user_node_gi user_node_gr user_node_safety current_gi current_gi_day
current_gi_qy gi_tobe gi_tobe_day gi_tobe_qy current_cycle current_cycle_day current_cycle_qy cycle_tobe cycle_tobe_day cycle_tobe_qy current_gr current_gr_day current_gr_qy gr_tobe gr_tobe_day gr_tobe_qy current_safety current_safety_day
current_safety_qy safety_tobe safety_tobe_day safety_tobe_qy current_cycle_flag current_gi_flag current_gr_flag current_price current_safety_flag,zero_alert`;

  const subquery = ` Parent {${queryCol} } No_Of_Pages Children_01 {${queryCol} } Children_02 {${queryCol} } Children_03 {${queryCol}} Children_04 { ${queryCol} }}`;

  let queryString = "";

  if (data["Children_01"] != null && data["Children_01"] != "") {
    queryString += `,att_02:${data["Children_01"]}`;
    if (data["Children_01_val"] != "" && data["Children_01_val"] != null)
      queryString += `,val_02:${data["Children_01_val"].split("_")[0]}`;
  }
  if (data["Children_02"] != null && data["Children_02"] != "") {
    queryString += `,att_03:${data["Children_02"]}`;
    if (data["Children_02_val"] != "" && data["Children_02_val"] != null)
      queryString += `,val_03:${data["Children_02_val"].split("_")[0]}`;
  }

  if (data["Children_03"] != null && data["Children_03"] != "") {
    queryString += `,att_04:${data["Children_03"]}`;
    if (data["Children_03_val"] != "" && data["Children_03_val"] != null)
      queryString += `,val_04:${data["Children_03_val"].split("_")[0]}`;
  }

  if (data["Children_04"] != null && data["Children_04"] != "") {
    queryString += `,att_05:${data["Children_04"]}`;
  }

  let newQuery = `query MyQuery {
        LOAD_Preview(${filter_subpart_loc}limit: 10, offset:0, MonthYear:"${data.MonthYear}", CWID:"${data.CWID}",table_name: "${data.table_name}" , att_01:"GSM", val_01:"${data.GSM_val}" ${queryString}){
          ${subquery}
          }`;

  return newQuery;
}

function getNodId(level) {
  // debugger
  let nodeId = "";
  // console.log(document.getElementById("subChild1"))
  if (level == 1 && document.getElementById("subChild1") != null) {
    const subChild1 = document.getElementById("subChild1").children;
    // //console.log();
    for (let i = 0; i < subChild1.length; i++) {
      if (nodeId != "" && subChild1[i].id == "")
        nodeId += "," + subChild1[i].children[0].id.split("_")[0];
      else if (subChild1[i].id == "")
        nodeId = subChild1[i].children[0].id.split("_")[0];
    }
  } else if (level == 2 && document.getElementById("subChild2") != null) {
    const subChild2 = document.getElementById("subChild2").children;
    // //console.log();

    for (let i = 0; i < subChild2.length; i++) {
      if (nodeId != "" && subChild2[i].id == "")
        nodeId += "," + subChild2[i].children[0].id.split("_")[0];
      else if (subChild2[i].id == "")
        nodeId = subChild2[i].children[0].id.split("_")[0];
    }
  } else if (level == 3 && document.getElementById("subChild3") != null) {
    const subChild3 = document.getElementById("subChild3").children;
    // //console.log();
    for (let i = 0; i < subChild3.length; i++) {
      if (nodeId != "" && subChild3[i].id == "")
        nodeId += "," + subChild3[i].children[0].id.split("_")[0];
      else if (subChild3[i].id == "")
        nodeId = subChild3[i].children[0].id.split("_")[0];
    }
  } else if (level == 4 && document.getElementById("subChild4") != null) {
    const subChild4 = document.getElementById("subChild4").children;
    // //console.log();
    for (let i = 0; i < subChild4.length; i++) {
      if (nodeId != "" && subChild4[i].id == "")
        nodeId += "," + subChild4[i].children[0].id.split("_")[0];
      else if (subChild4[i].id == "")
        nodeId = subChild4[i].children[0].id.split("_")[0];
    }
  }

  return nodeId;
}

export const getSaveNormsQuery = (
  filtevalues1,
  gsm,
  // brand,
  // com,
  // location,
  // product,
  // dataRef,
  name,
  ToggleButton,
  monthYear,
  dataForSaving,
  normUom,
  nodesEdited,
  // filterValuesHierarchyLevel,
  // masterChartData,
  // total,
  // stepChanged,
  currentUser,
  cwid,
  tableName,
  unit,
  filterQuery
  // normSpAsIsData
) => {
  const createBy = currentUser;

  if (gsm != "") {
    const filtevalues = getNewFilterValues(filtevalues1);

    let userInput = getPreviewSaveInputQuery1(
      dataForSaving,
      normUom,
      nodesEdited
    );
    if (filtevalues.length > 0) {
      let queryString = "";
      let child1 = "",
        child2 = "",
        child3 = "",
        child4 = "";
      if (filtevalues.length == 2) {
        queryString = `,Children_01:"${filtevalues[1]}"`;
      } else if (filtevalues.length == 3) {
        child1 = getNodId(1);

        queryString = `,Children_01:"${filtevalues[1]}", Children_01_val:"${child1}"`;
      } else if (filtevalues.length == 4) {
        child1 = getNodId(1);
        child2 = getNodId(2);

        if (child1 != "" && child2 != "" && child3 == "")
          queryString = `,Children_01:"${filtevalues[1]}", Children_01_val:"${child1}",Children_02:"${filtevalues[2]}"`;
        else if (child1 != "" && child2 == "")
          queryString = `,Children_01:"${filtevalues[1]}"`;
      } else if (filtevalues.length == 5) {
        child1 = getNodId(1);
        child2 = getNodId(2);
        child3 = getNodId(3);

        if (child1 != "" && child2 != "" && child3 != "" && child4 == "")
          queryString = `,Children_01:"${filtevalues[1]}", Children_01_val:"${child1}" , Children_02:"${filtevalues[2]}", Children_02_val:"${child2}", Children_03:"${filtevalues[3]}", Children_03_val:"${child3}",Children_04:"${filtevalues[4]}"`;
        else if (child1 != "" && child2 != "" && child3 == "" && child4 == "")
          queryString = `,Children_01:"${filtevalues[1]}", Children_01_val:"${child1}" , Children_02:"${filtevalues[2]}"`;
        else if (child1 != "" && child2 == "" && child3 == "" && child4 == "")
          queryString = `,Children_01:"${filtevalues[1]}"`;
      }

      // 		let masterFilterQueryData = "";

      // 		const count = total.indexOf(stepChanged) + 1;
      // 		const { type, value } = getType(gsm, brand, com, location, monthYear, product);
      // 		const values = getValues(value, total);

      // 		if (count == 1) {
      // 			//masterFilterQueryData += `att_01_top: "${total[0]}", val_01_top: "${values[0]}"`;
      // 		} else if (count == 2) {
      // 			masterFilterQueryData += ` att_02_top: "${total[1]}", val_02_top: "${values[1]}"`;
      // 		} else if (count == 3) {
      // 			masterFilterQueryData += `att_02_top: "${total[1]}", val_02_top: "${values[1]}",
      //     att_03_top: "${total[2]}", val_03_top: "${values[2]}" `;
      // 		} else if (count == 4) {
      // 			masterFilterQueryData += `
      //     att_02_top: "${total[1]}", val_02_top: "${values[1]}",
      //     att_03_top: "${total[2]}", val_03_top: "${values[2]}",
      //     att_04_top: "${total[3]}", val_04_top: "${values[3]}"`;
      // 		} else if (count == 5) {
      // 			masterFilterQueryData += `
      //     att_02_top: "${total[1]}", val_02_top: "${values[1]}",
      //     att_03_top: "${total[2]}", val_03_top: "${values[2]}",
      //     att_04_top: "${total[3]}", val_04_top: "${values[3]}",
      //     att_05_top: "${total[4]}", val_05_top: "${values[4]}"`;
      // 		} else if (count == 6) {
      // 			masterFilterQueryData += `
      //     att_02_top: "${total[1]}", val_02_top: "${values[1]}",
      //     att_03_top: "${total[2]}", val_03_top: "${values[2]}",
      //     att_04_top: "${total[3]}", val_04_top: "${values[3]}",
      //     att_05_top: "${total[4]}", val_05_top: "${values[4]}",
      //     att_06_top: "${total[5]}", val_06_top: "${values[5]}"`;
      // 		}
      // 		console.log(" masterFilterQueryData--", masterFilterQueryData);
      // if(masterFilterQueryData!=""){
      // 		return `query MyQuery {
      //   CustomizedView_SAVE( ${masterFilterQueryData},CWID:"${cwid}",
      //    table_name:"${tableName}",sort_by: ${unit.toLowerCase()}, created_by:"${createBy}",MonthYear:"${monthYear}",  unit:"${unit.toLowerCase()}", Toggle:"${ToggleButton}",scenario_name:"${name}", hierarchy_level:"${filtevalues.toString()}", GSM:"GSM", GSM_val:"${gsm}"   ${queryString} ${userInput})
      //   }`;
      // }
      // else
      {
        return `query MyQuery { 
            CustomizedView_SAVE(CWID:"${cwid}",
             table_name:"${tableName}",${filterQuery}, created_by:"${createBy}",snapshot:"${monthYear}", unit:"${unit.toLowerCase()}",scenario_name:"${name}", hierarchy_level:"${filtevalues.toString()}", GSM_val:"${gsm}"   ${queryString} ${userInput})
            }`;
      }
      //}
    } else {
      return null;
    }
  }
};

export const getPreviewSaveInputQuery = (data, normUom, nodesEdited) => {
  let current_gi_flag = "";
  let gi_tobe = "";
  let user_unit_gi = "";

  let current_gr_flag = "";
  let gr_tobe = "";
  let user_unit_gr = "";

  let safety_tobe = "";
  let current_safety_flag = "";
  let user_unit_safety = "";

  let current_cycle_flag = "";
  let cycle_tobe = "";
  let user_unit_cycle = "";

  let price_tobe = "";
  let current_price_flag = "";

  let gi_node_ids = "";
  let gr_node_ids = "";
  let safety_node_ids = "";
  let cycle_node_ids = "";
  let price_node_ids = "";
  let level_of_change_gi = "",
    level_of_change_cy = "",
    level_of_change_gr = "",
    level_of_change_sf = "";

  nodesEdited.forEach((item) => {
    let currentGi =
      normUom === 2
        ? item.current_gi_day
        : normUom === 1
          ? item.current_gi_qy
          : item.current_gi;
    let giTobe =
      normUom === 2
        ? item.gi_tobe_day
        : normUom === 1
          ? item.gi_tobe_qy
          : item.gi_tobe;

    if (giTobe != currentGi) {
      gi_tobe += gi_tobe != "" ? ";" + giTobe : giTobe;
      gi_node_ids +=
        gi_node_ids != ""
          ? ";" + item.TreeNodeId.split("_")[0]
          : item.TreeNodeId.split("_")[0];
      user_unit_gi += user_unit_gi != "" ? ";" + normUom : normUom;
      level_of_change_gi +=
        level_of_change_gi != "" ? ";" + item.CustomLevel : item.CustomLevel;
    }
    if (item.current_gi_flag) {
      current_gi_flag +=
        current_gi_flag != ""
          ? ";" + item.TreeNodeId.split("_")[0]
          : item.TreeNodeId.split("_")[0];
    }

    let currentGr =
      normUom === 2
        ? item.current_gr_day
        : normUom === 1
          ? item.current_gr_qy
          : item.current_gr;
    let grTobe =
      normUom === 2
        ? item.gr_tobe_day
        : normUom === 1
          ? item.gr_tobe_qy
          : item.gr_tobe;

    if (grTobe != currentGr) {
      gr_tobe += gr_tobe != "" ? ";" + grTobe : grTobe;
      gr_node_ids +=
        gr_node_ids != ""
          ? ";" + item.TreeNodeId.split("_")[0]
          : item.TreeNodeId.split("_")[0];
      user_unit_gr += user_unit_gr != "" ? ";" + normUom : normUom;
      level_of_change_gr +=
        level_of_change_gr != "" ? ";" + item.CustomLevel : item.CustomLevel;
    }
    if (item.current_gr_flag) {
      current_gr_flag +=
        current_gr_flag != ""
          ? ";" + item.TreeNodeId.split("_")[0]
          : item.TreeNodeId.split("_")[0];
    }

    let currentSafety =
      normUom === 2
        ? item.current_safety_day
        : normUom === 1
          ? item.current_safety_qy
          : item.current_safety;
    let safetyTobe =
      normUom === 2
        ? item.safety_tobe_day
        : normUom === 1
          ? item.safety_tobe_qy
          : item.safety_tobe;

    if (safetyTobe != currentSafety) {
      safety_tobe += safety_tobe != "" ? ";" + safetyTobe : safetyTobe;
      safety_node_ids +=
        safety_node_ids != ""
          ? ";" + item.TreeNodeId.split("_")[0]
          : item.TreeNodeId.split("_")[0];
      user_unit_safety += user_unit_safety != "" ? ";" + normUom : normUom;
      level_of_change_sf +=
        level_of_change_sf != "" ? ";" + item.CustomLevel : item.CustomLevel;
      //level_of_change_sf
    }
    if (item.current_safety_flag) {
      current_safety_flag +=
        current_safety_flag != ""
          ? ";" + item.TreeNodeId.split("_")[0]
          : item.TreeNodeId.split("_")[0];
    }

    let currentCycle =
      normUom === 2
        ? item.current_cycle_day
        : normUom === 1
          ? item.current_cycle_qy
          : item.current_cycle;
    let cycleTobe =
      normUom === 2
        ? item.cycle_tobe_day
        : normUom === 1
          ? item.cycle_tobe_qy
          : item.cycle_tobe;

    if (cycleTobe != currentCycle) {
      cycle_tobe += cycle_tobe != "" ? ";" + cycleTobe : cycleTobe;
      cycle_node_ids +=
        cycle_node_ids != ""
          ? ";" + item.TreeNodeId.split("_")[0]
          : item.TreeNodeId.split("_")[0];
      user_unit_cycle += user_unit_cycle != "" ? ";" + normUom : normUom;
      level_of_change_cy +=
        level_of_change_cy != "" ? ";" + item.CustomLevel : item.CustomLevel;
    }
    if (item.current_cycle_flag) {
      current_cycle_flag +=
        current_cycle_flag != ""
          ? ";" + item.TreeNodeId.split("_")[0]
          : item.TreeNodeId.split("_")[0];
    }

    // let currentPrice = normUom === 1 ? item.current_price_qy : item.current_price;
    let currentPrice = item.current_price;
    if (item.price_tobe != currentPrice) {
      price_tobe += price_tobe != "" ? ";" + item.price_tobe : item.price_tobe;
      price_node_ids +=
        price_node_ids != ""
          ? ";" + item.TreeNodeId.split("_")[0]
          : item.TreeNodeId.split("_")[0];
      //user_unit_price += user_unit_price != "" ? "," + normUom : normUom;
    }
    if (item.current_price_flag) {
      current_price_flag +=
        current_price_flag != ""
          ? ";" + item.TreeNodeId.split("_")[0]
          : item.TreeNodeId.split("_")[0];
    }
  });

  let queryString = "";
  // let quote ="\"";

  if (gi_tobe) {
    queryString += `,user_node_gi:"${gi_node_ids}" ,user_input_gi:"${gi_tobe}" `;
  }
  if (current_gi_flag) {
    queryString += `,user_flag_gi:"${current_gi_flag}"`;
  }

  if (gr_tobe) {
    queryString += `, user_node_gr:"${gr_node_ids}" ,user_input_gr:"${gr_tobe}"`;
  }
  if (current_gr_flag) {
    queryString += `,user_flag_gr:"${current_gr_flag}"`;
  }

  if (safety_tobe) {
    queryString += `,user_node_sf:"${safety_node_ids}" , user_input_sf: "${safety_tobe}"`;
  }
  if (current_safety_flag) {
    queryString += `,user_flag_sf:"${current_safety_flag}"`;
  }
  if (cycle_tobe) {
    queryString += `,user_node_cy:"${cycle_node_ids}" , user_input_cy:"${cycle_tobe}"`;
  }
  if (current_cycle_flag) {
    queryString += `,user_flag_cy:"${current_cycle_flag}"`;
  }
  if (price_tobe) {
    queryString += `user_node_price:"${price_node_ids}" , user_input_price: "${price_tobe}"`;
  }
  if (current_price_flag) {
    queryString += `,user_flag_price:"${current_price_flag}"`;
  }
  if (level_of_change_gi) {
    queryString += `,level_of_change_gi:"${level_of_change_gi}"`;
  }
  if (level_of_change_gr) {
    queryString += `,level_of_change_gr:"${level_of_change_gr}"`;
  }
  if (level_of_change_sf) {
    queryString += `,level_of_change_sf:"${level_of_change_sf}"`;
  }
  if (level_of_change_cy) {
    queryString += `,level_of_change_cy:"${level_of_change_cy}"`;
  }

  return queryString ? queryString : null;
};

export const getPreviewSaveInputQuery1 = (data, normUom, nodesEdited) => {
  let current_gi_flag = "";

  let current_gr_flag = "";

  let current_safety_flag = "";

  let current_cycle_flag = "";

  let current_price_flag = "";
  nodesEdited.forEach((item) => {
    if (item.current_gi_flag) {
      current_gi_flag +=
        current_gi_flag != ""
          ? "," + item.TreeNodeId.split("_")[0]
          : item.TreeNodeId.split("_")[0];
    }

    if (item.current_gr_flag) {
      current_gr_flag +=
        current_gr_flag != ""
          ? "," + item.TreeNodeId.split("_")[0]
          : item.TreeNodeId.split("_")[0];
    }

    if (item.current_safety_flag) {
      current_safety_flag +=
        current_safety_flag != ""
          ? "," + item.TreeNodeId.split("_")[0]
          : item.TreeNodeId.split("_")[0];
    }

    if (item.current_cycle_flag) {
      current_cycle_flag +=
        current_cycle_flag != ""
          ? "," + item.TreeNodeId.split("_")[0]
          : item.TreeNodeId.split("_")[0];
    }

    if (item.current_price_flag) {
      current_price_flag +=
        current_price_flag != ""
          ? "," + item.TreeNodeId.split("_")[0]
          : item.TreeNodeId.split("_")[0];
    }
  });

  let queryString = "";

  if (current_gi_flag) {
    queryString += `,user_flag_gi:"${current_gi_flag}"`;
  }

  if (current_gr_flag) {
    queryString += `,user_flag_gr:"${current_gr_flag}"`;
  }

  if (current_safety_flag) {
    queryString += `,user_flag_safety:"${current_safety_flag}"`;
  }

  if (current_cycle_flag) {
    queryString += `,user_flag_cycle:"${current_cycle_flag}"`;
  }

  return queryString ? queryString : "";
};

export const getPreviewNormsQuery = (
  filtevalues1,
  gsm,
  toggle,
  monthYear,
  dataForSaving,
  normUom,
  nodesEdited,
  cwid,
  unit,
  filter_subpart_loc
) => {
  const filtevalues = getNewFilterValues(filtevalues1);
  if (gsm != "") {
    let userInput = getPreviewSaveInputQuery(
      dataForSaving,
      normUom,
      nodesEdited
    );
    if (userInput) {
      if (filtevalues.length > 0) {
        let queryString = "";
        let child1 = "",
          child2 = "",
          child3 = "",
          child4 = "";
        if (filtevalues.length == 2) {
          queryString = `,Children_01:"${filtevalues[1]}"`;
        } else if (filtevalues.length == 3) {
          child1 = getNodId(1);

          queryString = `,Children_01:"${filtevalues[1]}", Children_01_val:"${child1}"`;
        } else if (filtevalues.length == 4) {
          child1 = getNodId(1);
          child2 = getNodId(2);
          if (child1 != "" && child2 != "" && child3 == "")
            queryString = `,Children_01:"${filtevalues[1]}", Children_01_val:"${child1}",Children_02:"${filtevalues[2]}"`;
          else if (child1 != "" && child2 == "")
            queryString = `,Children_01:"${filtevalues[1]}"`;
        } else if (filtevalues.length == 5) {
          child1 = getNodId(1);
          child2 = getNodId(2);
          child3 = getNodId(3);
          child4 = getNodId(4);
          if (child1 != "" && child2 != "" && child3 != "" && child4 != "")
            queryString = `,Children_01:"${filtevalues[1]}", Children_01_val:"${child1}" , Children_02:"${filtevalues[2]}", Children_02_val:"${child2}", Children_03:"${filtevalues[3]}", Children_03_val:"${child3}",Children_04:"${filtevalues[4]}"`;
          if (child1 != "" && child2 != "" && child3 != "" && child4 == "")
            queryString = `,Children_01:"${filtevalues[1]}", Children_01_val:"${child1}" , Children_02:"${filtevalues[2]}", Children_02_val:"${child2}", Children_03:"${filtevalues[3]}"`;
          else if (child1 != "" && child2 != "" && child3 == "" && child4 == "")
            queryString = `,Children_01:"${filtevalues[1]}", Children_01_val:"${child1}" ,Children_02:"${filtevalues[2]}"`;
          else if (child1 != "" && child2 == "" && child3 == "" && child4 == "")
            queryString = `,Children_01:"${filtevalues[1]}"`;
        }
        const semiColonSeprator = filtevalues.toString().replaceAll(",", ";");

        return `mutation MyMutation {
           Preview(${filter_subpart_loc}Toggle: "${toggle}",hierarchy_level:"${semiColonSeprator}" , unit:"${unit.toLowerCase()}",CWID:"${cwid}", MonthYear:"${monthYear}",
           GSM:"GSM", GSM_val:"${gsm}" ${queryString} ${userInput})
		   {
        table_name
        validation_status
		}
	  
	}`;
      }
    } else {
      return null;
    }
  }
};

// export function getSavePQuery(total, stepChanged, Gsm, Brand, Com, Location, MonthYear, Product,cwid,tableName) {
// 	let query = "";
// 	let step = "";

// 	const count = total.indexOf(stepChanged) + 1;

// 	const { type, value } = getType(Gsm, Brand, Com, Location, MonthYear, Product);
// 	const vales = getValues(value, total);

// 	let queryCol=` Id Brand { Type Brand Potential Brand_QY Potential_QY } Brand_Location { Type Brand_Location Potential Brand_Location_QY Potential_QY } GSMNetwork { Type GSMNetwork Potential GSMNetwork_QY Potential_QY } Location { Type Location Potential Location_QY Potential_QY } SKU { Type SKU Potential SKU_QY Potential_QY } Product_Location { Type Product_Location Potential Product_Location_QY Potential_QY}`
//   let subQuery=`  SP_ASIS { ${queryCol} } SP_Historized { ${queryCol} } SP_Improved { ${queryCol} }`;

// 	if (count == 1) {

//     let queryString="";

//     if(tableName!="" && tableName!=undefined)
//     {
//       queryString+=`,customized_table_name: "${tableName}"`;
//     }

// 		query = `query MyQuery {SavingPotential(CWID:"${cwid}",att_01: "${total[0]}", val_01: "${vales[0]}" ${queryString})  {
//     ${subQuery}
//     }
//   }`;
// 	} else if (count == 2) {

//     let queryString="";
//     if(vales[1]!="" && vales[0]!=undefined)
//     queryString+=` att_02: "${total[1]}", val_02: "${vales[1]}"`
//     if(tableName!="" && tableName!=undefined)
//     {
//       queryString+=`,customized_table_name: "${tableName}"`;
//     }
// 		query = `query MyQuery {
//         SavingPotential(CWID:"${cwid}",att_01:"${total[0]}", val_01: "${vales[0]}",
//           ${queryString} )  {
//             ${subQuery}
//             }
//           }`;
// 	} else if (count == 3) {

//     let queryString="";
//     if(vales[1]!="" && vales[1]!=undefined)
//     queryString+=`,att_02:"${total[1].trim()}", val_02:"${vales[1]}"`;
//     if(vales[2]!="" && vales[2]!=undefined)
//     queryString+=`,att_03:"${total[2].trim()}", val_03:"${vales[2]}"`;
//     if(tableName!="" && tableName!=undefined)
//     {
//       queryString+=`,customized_table_name: "${tableName}"`;
//     }
// 		query = `query MyQuery {
//         SavingPotential(CWID:"${cwid}",att_01:"${total[0].trim()}", val_01:"${vales[0]}"
//           ${queryString})   {
//             ${subQuery}
//             }
//           }`;
// 	} else if (count == 4) {

//     let queryString="";
//     if(vales[1]!="" && vales[1]!=undefined)
//     queryString+=` , att_02:"${total[1]}", val_02:"${vales[1]}"`
//     if(vales[2]!="" && vales[2]!=undefined)
//     queryString+=` , att_03:"${total[2]}", val_03:"${vales[2]}"`
//     if(vales[3]!="" && vales[3]!=undefined)
//     queryString+=` , att_04:"${total[3]}", val_04:"${vales[3]}"`
//     if(tableName!="" && tableName!=undefined)
//     {
//       queryString+=`,customized_table_name: "${tableName}"`;
//     }

// 		query = `query MyQuery {
//         SavingPotential(CWID:"${cwid}",att_01:"${total[0]}", val_01:"${vales[0]}"
//           ${queryString})  {
//             ${subQuery} }
//           }`;
// 	} else if (count == 5) {
//     let queryString="";
//     if(vales[1]!="" && vales[1]!=undefined)
//     queryString+=`, att_02:"${total[1].trim()}",  val_02:"${vales[1]}"`;
//     if(vales[2]!="" && vales[2]!=undefined)
//     queryString+=`, att_03:"${total[2].trim()}",
//     val_03:"${vales[2]}"`;
//     if(vales[3]!="" && vales[3]!=undefined)
//     queryString+=`, att_04:"${total[3].trim()}", val_04:"${vales[3]}"`;
//     if(vales[4]!="" && vales[4]!=undefined)
//     queryString+=`, att_05:"${total[4].trim()}", val_05:"${vales[4]}"`;
//     if(tableName!="" && tableName!=undefined)
//     {
//       queryString+=`,customized_table_name: "${tableName}"`;
//     }
// 		query = `query MyQuery {
//         SavingPotential(CWID:"${cwid}",att_01:"${total[0].trim()}",val_01:"${vales[0]}" ${queryString})  {
//           ${subQuery}
//         }
//       }`;
// 	} else if (count == 6) {
//     let queryString="";
//     if(vales[1]!="" && vales[1]!=undefined)
//     queryString+=`, att_02:"${total[1].trim()}",  val_02:"${vales[1]}"`;
//     if(vales[2]!="" && vales[2]!=undefined)
//     queryString+=`, att_03:"${total[2].trim()}",  val_03:"${vales[2]}"`;
//     if(vales[3]!="" && vales[3]!=undefined)
//     queryString+=`, att_04:"${total[3].trim()}",  val_04:"${vales[3]}"`;
//     if(vales[4]!="" && vales[4]!=undefined)
//     queryString+=`, att_05:"${total[4].trim()}",  val_05:"${vales[4]}"`;
//     if(vales[5]!="" && vales[5]!=undefined)
//     queryString+=`, att_06:"${total[5].trim()}",  val_06:"${vales[5]}"`;

//     if(tableName!="" && tableName!=undefined)
//     {
//       queryString+=`,customized_table_name: "${tableName}"`;
//     }

// 		query = `query MyQuery {
//       SavingPotential(CWID:"${cwid}",att_01:"${total[0].trim()}",val_01:"${vales[0]}"
//      ${queryString}){
//       ${subQuery}
//       }
//     }`;
// 	}
// 	////console.log(query);
// 	return query;
// }

//export const key = "da2-bgh52okljfb6pgbh5jw6pcmx6e";

export function getData(query) {
  return async () => {
    const response = await axios({
      url: endpoint,
      method: "POST",
      headers: { "x-api-key": key },

      data: {
        query: query,
      },
    }).then((response) => response.data.data);
  };
}

// function getType(Gsm, Brand, Com, Location, MonthYear, Product) {
// 	let count = 0;
// 	let type = "";
// 	let value = [];

// 	if (Gsm != null && Gsm != "") {
// 		//count=(count+1);
// 		type = type != "" ? type.concat(",GSM") : "GSM";
// 		value.push({ id: "GSM", value: Gsm });
// 	}
// 	if (Brand != null && Brand != "") {
// 		//count=(count+1);
// 		type = type != "" ? type.concat(",Brand") : "Brand";
// 		value.push({ id: "Brand", value: Brand });
// 		// value=(value!=""?value.concat(`,${Brand}`):Brand)
// 	}
// 	if (Com != null && Com != "") {
// 		//count=(count+1);
// 		type = type != "" ? type.concat(",CoM") : "CoM";
// 		value.push({ id: "CoM", value: Com });
// 	}
// 	if (Location != null && Location != "") {
// 		//count=(count+1);
// 		type = type != "" ? type.concat(",Location") : "Location";
// 		value.push({ id: "Location", value: Location });
// 	}
// 	if (MonthYear != null && MonthYear != "") {
// 		count = count + 1;
// 		type = type != "" ? type.concat(",MonthYear") : "MonthYear";
// 		value.push({ id: "MonthYear", value: MonthYear });
// 		//value=(value!=""?value.concat(`,${MonthYear}`):MonthYear)
// 	}
// 	if (Product != null && Product != "") {
// 		count = count + 1;
// 		type = type != "" ? type.concat(",Product") : "Product";
// 		value.push({ id: "Product", value: Product });
// 		//value=(value!=""?value.concat(`,${Product}`):Product)
// 	}

// 	// //console.log(type);
// 	return { type, value };
// }

// function getValues(values1, total) {
// 	const values = total.map((step) => {
// 		const filteredArray = values1.filter((value) => {
// 			if (value.id.toLowerCase() === step.toLowerCase()) return value.value;
// 		});
// 		//console.log(filteredArray);
// 		if (filteredArray.length > 0 && filteredArray != undefined) {
// 			return filteredArray[0].value.split("-")[0].trim();
// 		}
// 	});
// 	//const values=values
// 	//console.log(values);
// 	return values;
// }

// export function getQuery(total, stepChanged, Gsm, Brand, Com, Location, MonthYear, Product,cwid,tableName) {

// 	let query = "";
// 	let step = "";
//   const queryCol=`Cycle Cycle_qy Effects  Effects_qy GoodsIssued  GoodsIssued_qy  GoodsReceipt GoodsReceipt_qy  Id InTransit InTransit_qy Price Safety Safety_qy`

//   const subquery=` CHART { ASIS { ${queryCol}  }
//     Historized { ${queryCol} } Customized {${queryCol} } Improved { ${queryCol} } CURRENT {Id CURRENT CURRENT_qy} MA12PROJ {Id MA12Proj MA12Proj_qy } YeTarget { Id YeTarget YeTarget_qy }
//   }}`;

// 	const count = total.indexOf(stepChanged) + 1;
// 	const { type, value } = getType(Gsm, Brand, Com, Location, MonthYear, Product);
// 	const vales = getValues(value, total);

// 	if (count == 1) {
//     let queryString="";
//     if(tableName!="" && tableName!=undefined)
//     {
//       queryString+=`,customized_table_name: "${tableName}"`;
//     }
// 		query = `query MyQuery {FirstStepData(CWID:"${cwid}",att_01: "${total[0]}", val_01: "${vales[0]}" ${queryString}) {
//       ${subquery}
//           }`;
// 		step = "FirstStepData";
// 	} else if (count == 2) {

//     let queryString="";
//     if(vales[1]!="" && vales[1]!=undefined)
//     {
//       queryString+=`,att_02: "${total[1]}", val_02: "${vales[1]}"`;
//     }
//     if(tableName!="" && tableName!=undefined)
//     {
//       queryString+=`,customized_table_name: "${tableName}"`;
//     }

// 		query = `query MyQuery {
//             SecondStepData(CWID:"${cwid}",att_01:"${total[0]}", val_01: "${vales[0]}" ${queryString})
//                {
//                 ${subquery}
//           }`;
// 		step = "SecondStepData";
// 	} else if (count == 3) {
//     let queryString="";
//     if(vales[1]!="" && vales[1]!=undefined)
//     {
//       queryString+=`,att_02: "${total[1]}", val_02: "${vales[1]}"`;
//     }
//     if(vales[2]!="" && vales[2]!=undefined)
//     {
//       queryString+=`,att_03: "${total[2]}", val_03: "${vales[2]}"`;
//     }
//     if(tableName!="" && tableName!=undefined)
//     {
//       queryString+=`,customized_table_name: "${tableName}"`;
//     }
// 		query = `query MyQuery {
//             ThirdStepData(CWID:"${cwid}",att_01:"${total[0].trim()}", val_01:"${vales[0]}" ${queryString}
//                ) {
//                 ${subquery}
//           }`;
// 		step = "ThirdStepData";
// 	} else if (count == 4) {

//     let queryString="";
//     if(vales[1]!="" && vales[1]!=undefined)
//     {
//       queryString+=`,att_02: "${total[1]}", val_02: "${vales[1]}"`;
//     }
//     if(vales[2]!="" && vales[2]!=undefined)
//     {
//       queryString+=`,att_03: "${total[2]}", val_03: "${vales[2]}"`;
//     }
//     if(vales[3]!="" && vales[3]!=undefined)
//     {
//       queryString+=`,att_04: "${total[3]}", val_04: "${vales[3]}"`;
//     }
//     if(tableName!="" && tableName!=undefined)
//     {
//       queryString+=`,customized_table_name: "${tableName}"`;
//     }
// 		query = `query MyQuery {
//             FourthStepData(CWID:"${cwid}",att_01:"${total[0]}", val_01:"${vales[0]}" ${queryString}){
//               ${subquery}
//           }`;
// 		step = "FourthStepData";
// 	} else if (count == 5) {

//     let queryString="";
//     if(vales[1]!="" && vales[1]!=undefined)
//     {
//       queryString+=`,att_02: "${total[1]}", val_02: "${vales[1]}"`;
//     }
//     if(vales[2]!="" && vales[2]!=undefined)
//     {
//       queryString+=`,att_03: "${total[2]}", val_03: "${vales[2]}"`;
//     }
//     if(vales[3]!="" && vales[3]!=undefined)
//     {
//       queryString+=`,att_04: "${total[3]}", val_04: "${vales[3]}"`;
//     }
//     if(vales[4]!="" && vales[4]!=undefined)
//     {
//       queryString+=`,att_05: "${total[4]}", val_05: "${vales[4]}"`;
//     }
//     if(tableName!="" && tableName!=undefined)
//     {
//       queryString+=`,customized_table_name: "${tableName}"`;
//     }
// 		query = `query MyQuery {
//             FifthStepData(CWID:"${cwid}",att_01:"${total[0].trim()}",val_01:"${vales[0]}" ${queryString}) {
//               ${subquery}
//           }
//           `;
// 		step = "FifthStepData";
// 	} else if (count == 6) {
//     let queryString="";
//     if(vales[1]!="" && vales[1]!=undefined)
//     {
//       queryString+=`,att_02: "${total[1]}", val_02: "${vales[1]}"`;
//     }
//     if(vales[2]!="" && vales[2]!=undefined)
//     {
//       queryString+=`,att_03: "${total[2]}", val_03: "${vales[2]}"`;
//     }
//     if(vales[3]!="" && vales[3]!=undefined)
//     {
//       queryString+=`,att_04: "${total[3]}", val_04: "${vales[3]}"`;
//     }
//     if(vales[4]!="" && vales[4]!=undefined)
//     {
//       queryString+=`,att_05: "${total[4]}", val_04: "${vales[4]}"`;
//     }
//     if(vales[5]!="" && vales[5]!=undefined)
//     {
//       queryString+=`,att_06: "${total[5]}", val_06: "${vales[5]}"`;
//     }
//     if(tableName!="" && tableName!=undefined)
//     {
//       queryString+=`,customized_table_name: "${tableName}"`;
//     }

// 		query = `query MyQuery {
//            SixthStepData(CWID:"${cwid}",att_01:"${total[0].trim()}",val_01:"${vales[0]}" ${queryString}) {
//             ${subquery} }`;
// 		step = "SixthStepData";
// 	}
// 	////console.log(query);
// 	return { query, step, type };
// }

/*Raw Data from API on Filter Selection */
export function getChartData(data, kpis) {
  let chartData = [];
  if (data != null && data != "" && kpis != null) {
    const newdata = { Id: "MA12Proj", MA12Proj: data["MA12PROJ"].MA12Proj };

    if (kpis.indexOf("Current") != -1) chartData.push(data["CURRENT"]);
    if (kpis.indexOf("12 MA Projection") != -1) chartData.push(newdata);
    if (kpis.indexOf("YE Target") != -1) chartData.push(data["YeTarget"]);
    if (kpis.indexOf("YE Inventory Target") != -1)
      chartData.push(data["YE_Target"]);
    if (kpis.indexOf("Historized") != -1) chartData.push(data["Historized"]);
    if (kpis.indexOf("As-is") != -1) chartData.push(data["ASIS"]);
    if (kpis.indexOf("Improved") != -1) chartData.push(data["Improved"]);
    if (kpis.indexOf("Customized") != -1) chartData.push(data["Customized"]);
    if (kpis.indexOf("YE Inventory FC") != -1)
      chartData.push(
        changeTheInventoryForcastKey(data["YE_Inventory_Forecast"])
      );
  }

  return chartData;
}

const changeTheInventoryForcastKey = (datum) => {
  return {
    Id: "YE_Forecast",
    YECycle: datum.Cycle,
    YEEffects: datum.Effects,
    YEGoodsIssued: datum.GoodsIssued,
    YEGoodsReceipt: datum.GoodsReceipt,
    YEInTransit: datum.InTransit,
    YEPrice: datum.Price,
    YESafety: datum.Safety,
    YECycle_qy: datum.Cycle_qy != undefined ? datum.Cycle_qy : datum.Cycle,
    YEEffects_qy:
      datum.Effects_qy != undefined ? datum.Effects_qy : datum.Effects,
    YEGoodsIssued_qy:
      datum.GoodsIssued_qy != undefined
        ? datum.GoodsIssued_qy
        : datum.GoodsIssued,
    YEGoodsReceipt_qy:
      datum.GoodsReceipt_qy != undefined
        ? datum.GoodsReceipt_qy
        : datum.GoodsReceipt,
    YEInTransit_qy:
      datum.InTransit_qy != undefined ? datum.InTransit_qy : datum.InTransit,
    YEPrice: datum.Price,
    YESafety_qy: datum.Safety_qy != undefined ? datum.Safety_qy : datum.Safety,
  };
};

export function getChartDataQTY(data, kpis) {
  let chartData = [];

  if (data != null && data != "" && kpis != null) {
    const newdata = {
      Id: "MA12Proj",
      MA12Proj:
        data["MA12PROJ"].MA12Proj_qy != undefined
          ? data["MA12PROJ"].MA12Proj_qy
          : data["MA12PROJ"].MA12Proj,
    };

    if (kpis.indexOf("Current") != -1)
      chartData.push({
        Id: "CURRENT",
        CURRENT:
          data["CURRENT"].CURRENT_qy != undefined
            ? data["CURRENT"].CURRENT_qy
            : data["CURRENT"].CURRENT,
      });
    if (kpis.indexOf("12 MA Projection") != -1) chartData.push(newdata);
    if (kpis.indexOf("YE Target") != -1)
      chartData.push({
        Id: "Ye Target",
        YeTarget:
          data["YeTarget"].YeTarget_qy != undefined
            ? data["YeTarget"].YeTarget_qy
            : data["YeTarget"].YeTarget,
      });
    if (kpis.indexOf("YE Inventory Target") != -1)
      chartData.push({
        Id: "YE_Target",
        YE_Target:
          data["YE_Target"].YE_Target_qy != undefined
            ? data["YE_Target"].YE_Target_qy
            : data["YE_Target"].YE_Target,
      });
    if (kpis.indexOf("Historized") != -1)
      chartData.push({
        Id: "Historized",
        Cycle:
          data["Historized"].Cycle_qy != undefined
            ? data["Historized"].Cycle_qy
            : data["Historized"].Cycle,
        Effects:
          data["Historized"].Effects_qy != undefined
            ? data["Historized"].Effects_qy
            : data["Historized"].Effects,
        GoodsIssued:
          data["Historized"].GoodsIssued_qy != undefined
            ? data["Historized"].GoodsIssued_qy
            : data["Historized"].GoodsIssued,
        GoodsReceipt:
          data["Historized"].GoodsReceipt_qy != undefined
            ? data["Historized"].GoodsReceipt_qy
            : data["Historized"].GoodsReceipt,
        InTransit:
          data["Historized"].InTransit_qy != undefined
            ? data["Historized"].InTransit_qy
            : data["Historized"].InTransit,
        Price: data["Historized"].Price,
        Safety:
          data["Historized"].Safety_qy != undefined
            ? data["Historized"].Safety_qy
            : data["Historized"].Safety,
      });
    if (kpis.indexOf("As-is") != -1)
      chartData.push({
        Id: "AS-IS",
        Cycle:
          data["ASIS"].Cycle_qy != undefined
            ? data["ASIS"].Cycle_qy
            : data["ASIS"].Cycle,
        Effects:
          data["ASIS"].Effects_qy != undefined
            ? data["ASIS"].Effects_qy
            : data["ASIS"].Effects,
        GoodsIssued:
          data["ASIS"].GoodsIssued_qy != undefined
            ? data["ASIS"].GoodsIssued_qy
            : data["ASIS"].GoodsIssued,
        GoodsReceipt:
          data["ASIS"].GoodsReceipt_qy != undefined
            ? data["ASIS"].GoodsReceipt_qy
            : data["ASIS"].GoodsReceipt,
        InTransit:
          data["ASIS"].InTransit_qy != undefined
            ? data["ASIS"].InTransit_qy
            : data["ASIS"].InTransit,
        Price: data["ASIS"].Price,
        Safety:
          data["ASIS"].Safety_qy != undefined
            ? data["ASIS"].Safety_qy
            : data["ASIS"].Safety,
      });
    if (kpis.indexOf("Improved") != -1)
      chartData.push({
        Id: "Improved",
        Cycle:
          data["Improved"].Cycle_qy != undefined
            ? data["Improved"].Cycle_qy
            : data["Improved"].Cycle,
        Effects:
          data["Improved"].Effects_qy != undefined
            ? data["Improved"].Effects_qy
            : data["Improved"].Effects,
        GoodsIssued:
          data["Improved"].GoodsIssued_qy != undefined
            ? data["Improved"].GoodsIssued_qy
            : data["Improved"].GoodsIssued,
        GoodsReceipt:
          data["Improved"].GoodsReceipt_qy != undefined
            ? data["Improved"].GoodsReceipt_qy
            : data["Improved"].GoodsReceipt,
        InTransit:
          data["Improved"].InTransit_qy != undefined
            ? data["Improved"].InTransit_qy
            : data["Improved"].InTransit,
        Price: data["Improved"].Price,
        Safety:
          data["Improved"].Safety_qy != undefined
            ? data["Improved"].Safety_qy
            : data["Improved"].Safety,
      });
    if (kpis.indexOf("Customized") != -1)
      chartData.push({
        Id: "Customized",
        Cycle:
          data["Customized"].Cycle_qy != undefined
            ? data["Customized"].Cycle_qy
            : data["Customized"].Cycle,
        Effects:
          data["Customized"].Effects_qy != undefined
            ? data["Customized"].Effects_qy
            : data["Customized"].Effects,
        GoodsIssued:
          data["Customized"].GoodsIssued_qy != undefined
            ? data["Customized"].GoodsIssued_qy
            : data["Customized"].GoodsIssued,
        GoodsReceipt:
          data["Customized"].GoodsReceipt_qy != undefined
            ? data["Customized"].GoodsReceipt_qy
            : data["Customized"].GoodsReceipt,
        InTransit:
          data["Customized"].InTransit_qy != undefined
            ? data["Customized"].InTransit_qy
            : data["Customized"].InTransit,
        Price: data["Customized"].Price,
        Safety:
          data["Customized"].Safety_qy != undefined
            ? data["Customized"].Safety_qy
            : data["Customized"].Safety,
      });
    if (kpis.indexOf("YE Inventory FC") != -1)
      chartData.push({
        Id: "YE_Forecast",
        YECycle:
          data["YE_Inventory_Forecast"].Cycle_qy != undefined
            ? data["YE_Inventory_Forecast"].Cycle_qy
            : data["YE_Inventory_Forecast"].Cycle,
        YEEffects:
          data["YE_Inventory_Forecast"].Effects_qy != undefined
            ? data["YE_Inventory_Forecast"].Effects_qy
            : data["YE_Inventory_Forecast"].Effects,
        YEGoodsIssued:
          data["YE_Inventory_Forecast"].GoodsIssued_qy != undefined
            ? data["YE_Inventory_Forecast"].GoodsIssued_qy
            : data["YE_Inventory_Forecast"].GoodsIssued,
        YEGoodsReceipt:
          data["YE_Inventory_Forecast"].GoodsReceipt_qy != undefined
            ? data["YE_Inventory_Forecast"].GoodsReceipt_qy
            : data["YE_Inventory_Forecast"].GoodsReceipt,
        YEInTransit:
          data["YE_Inventory_Forecast"].InTransit_qy != undefined
            ? data["YE_Inventory_Forecast"].InTransit_qy
            : data["YE_Inventory_Forecast"].InTransit,
        YEPrice: data["YE_Inventory_Forecast"].Price,
        YESafety:
          data["YE_Inventory_Forecast"].Safety_qy != undefined
            ? data["YE_Inventory_Forecast"].Safety_qy
            : data["YE_Inventory_Forecast"].Safety,
      });
  }

  return chartData;
}

/*Push the new Customized Bar  data in Chart Data Scenario creation */
export const createChartData = (chartData1, data) => {
  let chartData = {};
  //let markerValue=0;

  if (chartData1 != null && chartData1 != "") {
    const newdata = {
      Id: "MA12Proj",
      MA12Proj: chartData1["MA12PROJ"].MA12Proj,
    };
    chartData["CURRENT"] = chartData1["CURRENT"];
    chartData["MA12PROJ"] = newdata;
    chartData["YeTarget"] = chartData1["YeTarget"];
    chartData["Historized"] = chartData1["Historized"];
    chartData["ASIS"] = chartData1["ASIS"];
    chartData["Improved"] = chartData1["Improved"];
    /*New KPI  YE_Target (12 MA inventory forcast) YE_Inventory Forcast(YE Inventory FC)*/
    chartData["YE_Inventory_Forecast"] = changeTheInventoryForcastKey(
      chartData1["YE_Inventory_Forecast"]
    );

    const newYeTargetdata1 = {
      Id: "YE_Target",
      YE_Target: chartData1["YE_Target"].YE_Target,
      YE_Target_qy: chartData1["YE_Target"].YE_Target_qy,
    };
    chartData["YE_Target"] = newYeTargetdata1;

    chartData["Customized"] = data;
  }

  return chartData;
};
export const createLoadScenarioChartData = (chartData1, scenarioData) => {
  let chartData = {};

  if (chartData1 != null && chartData1 != "") {
    const newMA12data = {
      Id: "MA12Proj",
      MA12Proj: scenarioData.ma12_val,
      MA12Proj_qy: scenarioData.ma12_qy,
    };
    const newCurrentdata = {
      Id: "CURRENT",
      CURRENT: scenarioData.current_val,
      CURRENT_qy: scenarioData.current_qy,
    };
    const newYeTargetdata = {
      Id: "Ye Target",
      YeTarget: scenarioData.ye_val,
      YeTarget_qy: scenarioData.ye_qy,
    };

    chartData["CURRENT"] = newCurrentdata;
    chartData["MA12PROJ"] = newMA12data;
    chartData["YeTarget"] = newYeTargetdata;
    chartData["ASIS"] = scenarioData["ASIS"];
    chartData["Customized"] = scenarioData["Customized"];
    chartData["Improved"] = chartData1["Improved"];
    chartData["Historized"] = chartData1["Historized"];
    /*New KPI  YE_Target (12 MA inventory forcast) YE_Inventory Forcast(YE Inventory FC)*/
    chartData["YE_Inventory_Forecast"] = changeTheInventoryForcastKey(
      chartData1["YE_Inventory_Forecast"]
    );

    const newYeTargetdata1 = {
      Id: "YE_Target",
      YE_Target: chartData1["YE_Target"].YE_Target,
      YE_Target_qy: chartData1["YE_Target"].YE_Target_qy,
    };
    chartData["YE_Target"] = newYeTargetdata1;
  }

  //console.log(chartData);
  return chartData;
};
export function createJsonArray(objectValue) {
  if (objectValue != null && objectValue != "") {
    const stringValue1 = objectValue
      .replace("{", "")
      .replace("}", "")
      .split(",");
    const code = stringValue1[0].split("=")[1];
    const Name = stringValue1[1].split("=")[1];

    return { code, Name };
  }
}

export function extractMasterValue(key, data) {
  const dataExtract = data[key];
  if (dataExtract != null) {
    const dropdown = dataExtract.map((datum) => createJsonArray(datum));
    return dropdown;
  }
}

export const loadScenarioQuery = (name, cwid) => {
  if (name != "") {
    return `query MyQuery {
	CustomizedView_LoadPreviousScenario(CWID:"${cwid}",scenario_name: "${name}") {
		CWID scenario_name table_name
		snapshot hierarchy_level created_at created_by
		saved_status sort_by unit upload_date GSM_Values GSM_Sel_All Location_Values Location_Sel_All CoM_Values CoM_Sel_All Brand_Values
		Brand_Sel_All Product_Values Product_Sel_All GSM GSM_val Children_01 Children_01_val Children_02 Children_02_val
		Children_03 Children_03_val Children_04 user_flag_cycle user_flag_gi user_flag_gr user_flag_safety
      }
        }`;
  } else {
    return `query MyQuery {
			CustomizedView_LoadPreviousScenario(CWID:"${cwid}") {
          created_at
          created_by
          list_of_scenarios
        }
      }`;
  }
};

export function addNodID(data) {
  let nodeId = [];
  if (data != null) {
    if (data["Parent"] != null) {
      for (let j = 0; j < data["Parent"].length; j++) {
        nodeId.push(data["Parent"][j].TreeNodeId);
      }
    }
    if (data["Children_01"] != null) {
      for (let j = 0; j < data["Children_01"].length; j++) {
        nodeId.push(data["Children_01"][j].TreeNodeId);
      }
    }
    if (data["Children_02"] != null) {
      for (let j = 0; j < data["Children_02"].length; j++) {
        nodeId.push(data["Children_02"][j].TreeNodeId);
      }
    }
    if (data["Children_03"] != null) {
      for (let j = 0; j < data["Children_03"].length; j++) {
        nodeId.push(data["Children_03"][j].TreeNodeId);
      }
    }
  }
  return nodeId;
}

export function setPreGsm(data) {
  let gsm = "";
  if (data != null) {
    if (data["Parent"] != null && data["Parent"]["TreeNodeId"] != null) {
      gsm = data["Parent"]["TreeNodeId"][0].split("_")[0];
    }
  }
  return gsm;
}
/*check Duplicate Name in Previously created scenario */
export function checkDuplicacy(name) {
  let flag = false;
  const tableEle = document.getElementById("scenarioList");
  if (tableEle && tableEle.rows.length > 0) {
    for (let i = 0; i < tableEle.rows.length; i++) {
      let cells = tableEle.rows[i].cells[0].id;
      if (name.trim().toLowerCase() === cells.trim().toLowerCase()) {
        flag = true;
        break;
      }
    }
  }
  return flag;
}

export function addCustomVariable(newData, nodeLevel) {
  if (newData != null && newData.length > 0) {
    newData.map((item) => {
      item.isEnable = true;
      item.isRetain = false;
      item.CustomLevel = nodeLevel;
      if (item.gi_tobe != null && item.gi_tobe != "")
        item.gi_tobe = item.gi_tobe;
      else item.gi_tobe = item.current_gi;
      if (item.gr_tobe != null && item.gr_tobe != "")
        item.gr_tobe = item.gr_tobe;
      else item.gr_tobe = item.current_gr;
      if (item.safety_tobe != null && item.safety_tobe != "")
        item.safety_tobe = item.safety_tobe;
      else item.safety_tobe = item.current_safety;
      if (item.cycle_tobe != null && item.cycle_tobe != "")
        item.cycle_tobe = item.cycle_tobe;
      else item.cycle_tobe = item.current_cycle;
      item.price_tobe = item.current_price;

      item.gi_tobe_qy = item.gi_tobe_qy ? item.gi_tobe_qy : item.current_gi_qy;
      item.gr_tobe_qy = item.gr_tobe_qy ? item.gr_tobe_qy : item.current_gr_qy;
      item.safety_tobe_qy = item.safety_tobe_qy
        ? item.safety_tobe_qy
        : item.current_safety_qy;
      item.cycle_tobe_qy = item.cycle_tobe_qy
        ? item.cycle_tobe_qy
        : item.current_cycle_qy;
      item.gi_tobe_day = item.gi_tobe_day
        ? item.gi_tobe_day
        : item.current_gi_day;
      item.gr_tobe_day = item.gr_tobe_day
        ? item.gr_tobe_day
        : item.current_gr_day;
      item.safety_tobe_day = item.safety_tobe_day
        ? item.safety_tobe_day
        : item.current_safety_day;
      item.cycle_tobe_day = item.cycle_tobe_day
        ? item.cycle_tobe_day
        : item.current_cycle_day;

      item.user_unit_gi = item.user_unit_gi ? item.user_unit_gi : 0;
      item.user_unit_gr = item.user_unit_gr ? item.user_unit_gr : 0;
      item.user_unit_safety = item.user_unit_safety ? item.user_unit_safety : 0;
      item.user_unit_cycle = item.user_unit_cycle ? item.user_unit_cycle : 0;

      // if(isPropsNormData){
      item.master_unit_gi = item.user_unit_gi ? item.user_unit_gi : 0;
      item.master_unit_gr = item.user_unit_gr ? item.user_unit_gr : 0;
      item.master_unit_safety = item.user_unit_safety
        ? item.user_unit_safety
        : 0;
      item.master_unit_cycle = item.user_unit_cycle ? item.user_unit_cycle : 0;

      item.master_gi_flag =
        item.current_gi_flag == "true" || item.current_gi_flag === true
          ? true
          : false;
      item.master_gr_flag =
        item.current_gr_flag === "true" || item.current_gr_flag === true
          ? true
          : false;
      item.master_safety_flag =
        item.current_safety_flag === "true" || item.current_safety_flag === true
          ? true
          : false;
      item.master_cycle_flag =
        item.current_cycle_flag == "true" || item.current_cycle_flag === true
          ? true
          : false;
      item.master_price_flag =
        item.current_price_flag == "true" || item.current_price_flag === true
          ? true
          : false;
      // }
      item.current_gi_flag =
        item.current_gi_flag == "true" || item.current_gi_flag === true
          ? true
          : false;
      item.current_gr_flag =
        item.current_gr_flag === "true" || item.current_gr_flag === true
          ? true
          : false;
      item.current_safety_flag =
        item.current_safety_flag === "true" || item.current_safety_flag === true
          ? true
          : false;
      item.current_cycle_flag =
        item.current_cycle_flag == "true" || item.current_cycle_flag === true
          ? true
          : false;
      item.current_price_flag =
        item.current_price_flag == "true" || item.current_price_flag === true
          ? true
          : false;
      item.user_node_cycle = item.user_node_cycle;
      item.user_node_gi = item.user_node_gi;
      item.user_node_gr = item.user_node_gr;
      item.user_node_safety = item.user_node_safety;
    });
  }
  return newData;
}

export function resetCustomVariable(newData) {
  if (newData != null && newData.length > 0) {
    newData.map((item) => {
      item.isEnable = true;
      item.gi_tobe = item.current_gi;
      item.gr_tobe = item.current_gr;
      item.safety_tobe = item.current_safety;
      item.cycle_tobe = item.current_cycle;
      item.price_tobe = item.current_price;

      item.gi_tobe_qy = item.current_gi_qy;
      item.gr_tobe_qy = item.current_gr_qy;
      item.safety_tobe_qy = item.current_safety_qy;
      item.cycle_tobe_qy = item.current_cycle_qy;

      item.gi_tobe_day = item.current_gi_day;
      item.gr_tobe_day = item.current_gr_day;
      item.safety_tobe_day = item.current_safety_day;
      item.cycle_tobe_day = item.current_cycle_day;

      item.user_unit_gi = item.master_unit_gi;
      item.user_unit_gr = item.master_unit_gr;
      item.user_unit_safety = item.master_unit_safety;
      item.user_unit_cycle = item.master_unit_cycle;

      item.current_gi_flag = item.master_gi_flag;
      item.current_gr_flag = item.master_gr_flag;
      item.current_safety_flag = item.master_safety_flag;
      item.current_cycle_flag = item.master_cycle_flag;
      item.current_price_flag = item.master_price_flag;
    });
  }
  return newData;
}

export function changeUomVariable(newData, normUom) {
  let dataArr = [];
  // debugger
  if (newData != null && newData.length > 0) {
    dataArr = newData.map((item) => {
      if (normUom === 0) {
        item.gi_tobe = item.current_gi;
        item.gr_tobe = item.current_gr;
        item.safety_tobe = item.current_safety;
        item.cycle_tobe = item.current_cycle;
        item.price_tobe = item.current_price;

        // item.user_unit_gi = 0;
      } else if (normUom === 1) {
        item.gi_tobe_qy = item.current_gi_qy;
        item.gr_tobe_qy = item.current_gr_qy;
        item.safety_tobe_qy = item.current_safety_qy;
        item.cycle_tobe_qy = item.current_cycle_qy;

        // item.user_unit_gi = 1;
      } else if (normUom === 2) {
        item.gi_tobe_day = item.current_gi_day;
        item.gr_tobe_day = item.current_gr_day;
        item.safety_tobe_day = item.current_safety_day;
        item.cycle_tobe_day = item.current_cycle_day;
      }
      return item;
    });
  }
  return dataArr;
}

export function retainChangedValueinTree(nodesEdited, item) {
  if (nodesEdited != null && nodesEdited != undefined) {
    nodesEdited.find((t) => {
      if (t.TreeNodeId === item.TreeNodeId) {
        item.cycle_tobe = t.cycle_tobe;
        item.cycle_tobe_day = t.cycle_tobe_day;
        item.cycle_tobe_qy = t.cycle_tobe_qy;
        item.gi_tobe = t.gi_tobe;
        item.gi_tobe_day = t.gi_tobe_day;
        item.gi_tobe_qy = t.gi_tobe_qy;

        item.gr_tobe = t.gr_tobe;
        item.gr_tobe_day = t.gr_tobe_day;
        item.gr_tobe_qy = t.gr_tobe_qy;
        item.safety_tobe = t.safety_tobe;
        item.safety_tobe_day = t.safety_tobe_day;
        item.safety_tobe_qy = t.safety_tobe_qy;
      }
    });
  }
}
/*export function convertDataFromPreview(newData) {
  let dataArr = [];
  // debugger
  if (newData.length>0) {

    dataArr=newData.map((item,index)=>({

      
      TreeNodeId: item.TreeNodeId,
      current_cycle: item.current_cycle,
      current_cycle_day: item.current_cycle_day,
      current_cycle_flag: item.current_cycle_flag,
      current_cycle_qy: item.current_cycle_qy,

      current_gi: item.current_gi,
      current_gi_day: item.current_gi_day,
      current_gi_flag: item.current_gi_flag,
      current_gi_qy: item.current_gi_qy,

      current_gr: item.current_gr,
      current_gr_day: item.current_gr_day,
      current_gr_flag: item.current_gr_flag,
      current_gr_qy: item.current_gr_qy,

      current_price: item.current_price,
      current_price_flag: item.current_price_flag,

      current_safety: newData.current_safety,
      current_safety_day: newData.current_safety_day,
      current_safety_flag: newData.current_safety_flag,
      current_safety_qy: newData.current_safety_qy,

      cycle_tobe: newData.cycle_tobe,
      gi_tobe: newData.gi_tobe,
      gr_tobe: newData.gr_tobe,
      price_tobe: newData.price_tobe,
      safety_tobe: newData.safety_tobe,

      cycle_tobe_qy: newData.cycle_tobe_qy
        ? newData.cycle_tobe_qy
        : newData.current_cycle_qy,
      gi_tobe_qy: newData.gi_tobe_qy ? newData.gi_tobe_qy : newData.current_gi_qy,
      gr_tobe_qy: newData.gr_tobe_qy ? newData.gr_tobe_qy : newData.current_gr_qy,
      safety_tobe_qy: newData.safety_tobe_qy
        ? newData.safety_tobe_qy
        : newData.current_safety_qy,

      cycle_tobe_day: newData.cycle_tobe_day
        ? newData.cycle_tobe_day
        : newData.current_cycle_day,
      gi_tobe_day: newData.gi_tobe_day ? newData.gi_tobe_day : newData.current_gi_day,
      gr_tobe_day: newData.gr_tobe_day ? newData.gr_tobe_day : newData.current_gr_day,
      safety_tobe_day: newData.safety_tobe_day
        ? newData.safety_tobe_day
        : newData.current_safety_day

      
    }))
	
  }
  return dataArr;
}*/
/*export function convertCustomizedChartDataFromPreview(newData,previewAsIsData) {
  let dataObj = {};
  // debugger
  if (newData != null) {
    dataObj = {
      "Cycle": newData.cycle_tobe,
      "Cycle_qy": newData.cycle_tobe_qy,
      "Effects": previewAsIsData.Effects?previewAsIsData.Effects:0,
      "Effects_qy": previewAsIsData.Effects_qy? previewAsIsData.Effects_qy:0,
      "InTransit": previewAsIsData.InTransit?previewAsIsData.InTransit:0,
      "InTransit_qy": previewAsIsData.InTransit_qy?previewAsIsData.InTransit_qy:0,
      "GoodsIssued": newData.gi_tobe,
      "GoodsIssued_qy": newData.gi_tobe_qy,
      "GoodsReceipt": newData.gr_tobe,
      "GoodsReceipt_qy": newData.gr_tobe_qy,
      "Id": "Customized",
      "Safety":  newData.safety_tobe,
      "Safety_qy":  newData.safety_tobe_qy
    }
  }
  return dataObj;
}*/

// export function getChartQuery(total,MonthYear,cwid) {
// 	let query = "";
// 	let step = "";
//   let subquery=` CHART { ASIS { Cycle Cycle_qy Effects Effects_qy GoodsIssued GoodsIssued_qy GoodsReceipt GoodsReceipt_qy Id InTransit InTransit_qy Price Safety Safety_qy } }`;
// 	const count = total.length +1;

// 	if (count == 1) {
// 		query = `query MyQuery {FirstStepData(CWID:"${cwid}",att_01:"MonthYear", val_01: "${MonthYear}") {

//         }
//           }`;
// 		step = "FirstStepData";
// 	} else if (count == 2) {
// 		query = `query MyQuery {
//             SecondStepData(CWID:"${cwid}",att_01:"MonthYear", val_01: "${MonthYear}",
//                  att_02: "${total[0].split("=")[0]}", val_02: "${total[0].split("=")[1].split("_")[0]}"){
//                  ${subquery}
//                 }
//           }`;
// 		step = "SecondStepData";
// 	} else if (count == 3) {
// 		query = `query MyQuery {
//             ThirdStepData(CWID:"${cwid}",att_01:"MonthYear", val_01: "${MonthYear}",
//             att_02: "${total[0].split("=")[0]}", val_02: "${total[0].split("=")[1].split("_")[0]}}",
//             att_03:"${total[1].split("=")[0]}", val_03:"${total[1].split("=")[1].split("_")[0]}") {
//                 ${subquery}
//                 }
//           }`;
// 		step = "ThirdStepData";
// 	} else if (count == 4) {

// 		query = `query MyQuery {
//             FourthStepData(CWID:"${cwid}",att_01:"MonthYear", val_01: "${MonthYear}",
//             att_02: "${total[0].split("=")[0]}", val_02: "${total[0].split("=")[1].split("_")[0]}",
//             att_03:"${total[1].split("=")[0]}", val_03:"${total[1].split("=")[1].split("_")[0]}",
//                  att_04:"${total[2].split("=")[0]}", val_04:"${total[2].split("=")[1].split("_")[0]}"){
//                   ${subquery}
//                 }
//           }`;
// 		step = "FourthStepData";
// 	} else if (count == 5) {

// 		query = `query MyQuery {
//             FifthStepData(CWID:"${cwid}",att_01:"MonthYear", val_01: "${MonthYear}",
//             att_02: "${total[0].split("=")[0]}", val_02: "${total[0].split("=")[1].split("_")[0]}"
//             att_03:"${total[1].split("=")[0]}", val_03:"${total[1].split("=")[1].split("_")[0]}",
//             att_04:"${total[2].split("=")[0]}", val_04:"${total[2].split("=")[1].split("_")[0]}",
//             att_05:"${total[3].split("=")[0]}", val_05:"${total[3].split("=")[1].split("_")[0]}") {
//               ${subquery}
//             }
//           }
//           `;
// 		step = "FifthStepData";
// 	} else if (count == 6) {

// 		query = `query MyQuery {
//            SixthStepData(CWID:"${cwid}",att_01:"MonthYear", val_01: "${MonthYear}",
//            att_02: "${total[0].split("=")[0]}", val_02: "${total[0].split("=")[1].split("_")[0]}"
//                att_03:"${total[1].split("=")[0]}", val_03:"${total[1].split("=")[1].split("_")[0]}",
//                att_04:"${total[2].split("=")[0]}", val_04:"${total[2].split("=")[1].split("_")[0]}",
//                att_05:"${total[3].split("=")[0]}", val_05:"${total[3].split("=")[1].split("_")[0]}",
//            att_06:"${total[4].split("=")[0]}", val_06:"${total[4].split("=")[1].split("_")[0]}") {
//             ${subquery}
//            }
//          }
//          `;
// 		step = "SixthStepData";
// 	}

// 	return { query, step };
// }

export const loadSavingPotential = async (
  inputParamForChartAndSavingPotential,
  token
) => {
  const savingPfColumn = `Id Brand {Type  Brand Potential Brand_QY Potential_QY Tick Tick_qy Comment Comment_qy} Brand_Location {Type Brand_Location Potential  Brand_Location_QY Potential_QY Tick Tick_qy Comment Comment_qy}GSMNetwork { Type GSMNetwork Potential GSMNetwork_QY Potential_QY  Tick Tick_qy Comment Comment_qy}
	Location { Type Location Potential Location_QY Potential_QY Tick Tick_qy Comment Comment_qy}Product_Location {Type Potential Product_Location Potential_QY Product_Location_QY Tick Tick_qy Comment Comment_qy}SKU { Type SKU Potential SKU_QY Potential_QY Tick Tick_qy Comment Comment_qy}`;
  const spQuery = `query MyQuery{
	PotentialFinderSavingPotential(${inputParamForChartAndSavingPotential}){LOW {
	SP_ASIS {${savingPfColumn}}
	SP_Customized{${savingPfColumn}}
	SP_Historized{${savingPfColumn}}
	SP_Improved{${savingPfColumn}}
	}
  TOP {
  SP_ASIS {${savingPfColumn}}
	SP_Customized{${savingPfColumn}}
	SP_Historized{${savingPfColumn}}
	SP_Improved{${savingPfColumn}}
  }
     }}`;

  const response = await axios({
    url: endpoint,
    method: "Post",
    headers: { "x-api-key": key },
    data: {
      query: spQuery,
    },
    cancelToken: token,
  });

  const spData = await response.data;
  return spData;
};

export const loadBarChart = async (
  inputParamForChartAndSavingPotential,
  token
) => {
  const barChartSubColumn = `Id Cycle Cycle_qy Effects Effects_qy GoodsIssued GoodsIssued_qy GoodsReceipt GoodsReceipt_qy InTransit InTransit_qy Price Safety Safety_qy`;
  const barChartQuery = `query MyQuery {PotentialFinderBars(${inputParamForChartAndSavingPotential}){
		CHART {
			CURRENT {
			  Id
			  CURRENT
			  CURRENT_qy
			}
			MA12PROJ {
			  Id
			  MA12Proj
			  MA12Proj_qy
			}
			YeTarget {
			  Id
			  YeTarget
			  YeTarget_qy
			}
			YE_Target {
				Id
				YE_Target
				YE_Target_qy
			  }
			ASIS {${barChartSubColumn}}
			Customized{${barChartSubColumn}}
			Historized{${barChartSubColumn}}
			Improved{${barChartSubColumn}}
			YE_Inventory_Forecast{${barChartSubColumn}}
		}
	}
   }`;
  const response = await axios({
    url: endpoint,
    method: "Post",
    headers: { "x-api-key": key },
    data: {
      query: barChartQuery,
    },
    cancelToken: token,
  });

  const barChart = await response.data;
  return barChart;
};

export const callAPI = async (query, token) => {
  const response = await axios({
    url: endpoint,
    method: "Post",
    headers: { "x-api-key": key },
    data: {
      query: query,
    },
    cancelToken: token,
  });

  const responseData = await response.data;
  return responseData;
};

export const setStatetoContext = (values) => {
  let objectArray = null;
  //console.log("values",values);
  if (values.toString() !== "" && values !== null) {
    //console.log("values",values);

    //const valueCode=values?.split(",");
    //const code=
    objectArray = values?.map((codeVal) => {
      //console.log("codeVal",codeVal)
      if (codeVal !== "" && codeVal !== "") {
        const code = codeVal.split("_");
        return { code: code[0], name: `${code[0]}-${code[1]}` };
      }
    });
  }
  //console.log("objectArray",objectArray)
  return objectArray;
};
export const querySavingPotentialEditedSave = (comments, entryInfo, monthYear, tickers, typeNorm, typeSelect) => {
  return `query MyQuery {
    SavingPotentialSave(
      comment_info: "${comments}", 
      entry_information: "${entryInfo}", 
      month_snapshot: "${monthYear}", 
      ticker_status: "${tickers}", 
      type_select: "${typeNorm}", 
      type_norm: "${typeSelect}"
    )
   }`;

}

export const loadFilters = (portal, cwid) => {
  return `query MyQuery {
							 FiltersLoad(CWID: "${cwid}", portlet: "${portal}")
               {
      Brand_Sel_All
      Brand_Values
      CoM_Sel_All
      CoM_Values
      GSM_Sel_All
      GSM_Values
      Location_Sel_All
      Location_Values
      Product_Sel_All
      Product_Values
      month_start_or_cur
      month_end
      }
      }`;

};


export const loadFilterss = async (
  inputParamFiltersload,
  token
) => {


  const filtersloadQuery = `query MyQuery {
    FiltersLoad(${inputParamFiltersload}) {
      Brand_Sel_All
      Brand_Values
      CoM_Sel_All
      CoM_Values
      GSM_Sel_All
      GSM_Values
      Location_Sel_All
      Location_Values
      Product_Sel_All
      Product_Values
      month_start_or_cur
      month_end
    }
  }`;

  const response = await axios({
    url: endpoint,
    method: "POST",
    headers: { "x-api-key": key },
    data: {
      query: filtersloadQuery,
    },
    cancelToken: token,
  });

  return response.data;
};
