export const authorityUrl = "https://login.microsoftonline.com/fcb2b37b-5da0-466b-9b83-0014b67a7c78";

// End point URL for Power BI API
export const powerBiApiUrl= "https://api.powerbi.com/";

// Scope for securing access token
export const scopeBase = ["https://analysis.windows.net/powerbi/api/Report"];

// Scope for securing access token

//export const  redirectUri= "https://iscdev.auth.eu-central-1.amazoncognito.com/saml2/idpresponse";

// Client Id (Application Id) of the AAD app.
//export const clientId= "ad8e097e-5dd5-4c52-84ba-f86a8bf6b6c2";

// Id of the workspace where the report is hosted
export const workspaceId = "816ba5ee-5a3e-49a9-a4c0-a69d1f626333";

// Id of the report to be embedded
export const reportId = "40472567-baba-4a29-8f80-b911b5bf0001";
export const authenticationMode="serviceprincipal";
  
//export const scopeBase= "https://analysis.windows.net/powerbi/api/.default";
   
export const clientSecret= process.env.REACT_APP_CLIENT_SECRET;
export const tenantId= "fcb2b37b-5da0-466b-9b83-0014b67a7c78";
//export const authorityUrl = "https://login.windows.net/common/oauth2/authorize/";