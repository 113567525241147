import React, {useState,useEffect} from 'react'
import pfContext from './PfContext';

const PfState=(props)=> {
   // const [scenario,setScenario]=useState("");
    const [gsmNetwork,setGsmNetwork]=useState([]);
    const [location,setLocation]=useState([]);
    const [pipeline,setPipeline]=useState([]);
    const [com,setCom]=useState([]);
    const [product,setProduct]=useState([]);
    const [steps,setSteps]=useState([]);
    const [changedStep,setChangedStep]=useState(null);
    const [isGsmSelectAll,setIsGsmSelectAll]=useState(true);
    const [isBrandSelectAll,setIsBrandSelectAll]=useState(true);
    const [isLocationSelectAll,setIsLocationSelectAll]=useState(true);
    const [isCoMSelectAll,setIsCoMSelectAll]=useState(true);
    const [isProductSelectAll,setIsProductSelectAll]=useState(true);

    const [isChecked,setIsChecked]=useState(false);
    const [showTooltips, setShowTooltips]=useState(false);
    const [buttonHoverStates, setButtonHoverStates]=useState("");
    const [isResetContext,setIsResetContext]=useState(false)
  

    useEffect(()=>{
      if(isResetContext)
      {
        setGsmNetwork([]);
    setLocation([]);
    setCom([]);
    setPipeline([]);
    setProduct([]);
    setIsGsmSelectAll(true);
    setIsBrandSelectAll(true);
    setIsLocationSelectAll(true);
    setIsProductSelectAll(true);
    setIsCoMSelectAll(true);
    setIsResetContext(true);
       // resetFilters();
      }

    },[isResetContext])
  

    const getFilterValues=()=>{
      const gsmCode=(gsmNetwork!==undefined && gsmNetwork!==null)?gsmNetwork.map((item)=>{if(item!==undefined)
        return (item.code).toString()}):"";
      const locCode=(location!==undefined && location!==null)?location.map((item)=>{if(item!==undefined)
        return (item.code).toString()}):"";
      const comCode=(com!==undefined && com!==null)?com.map((item)=>{if(item!==undefined)
        return (item.code).toString()}):"";
      const proCode=(product!==undefined && product!==null)?product.map((item)=>{if(item!==undefined)
        return (item.code).toString()}):"";
      const pipCode=(pipeline!==undefined && pipeline!==null)?pipeline.map((item)=>{if(item!==undefined)
        return (item.code).toString()}):"";

      return{gsmCode,locCode,comCode,proCode,pipCode};
    }


  return (
    <pfContext.Provider value={{gsmNetwork,location,pipeline,com,product,steps,setSteps,setGsmNetwork,changedStep,setChangedStep,
    setLocation,setCom,setProduct,setPipeline,isGsmSelectAll,setIsGsmSelectAll,
    isBrandSelectAll,setIsBrandSelectAll,isLocationSelectAll,setIsLocationSelectAll,isCoMSelectAll,setIsCoMSelectAll,isProductSelectAll,
    setIsProductSelectAll,isChecked,setIsChecked,showTooltips,setIsResetContext, setShowTooltips, buttonHoverStates,getFilterValues, setButtonHoverStates}}>
      
        {props.children}
    </pfContext.Provider>
   
  )
}

export default PfState;
