import React from 'react'
import "./modalPopup.css";
const ModalPopup=(props)=> {
    //console.log("hell0",props.show);
   return ( <div className="modal" style={{visibility:(props.show)?"visible":"hidden"}}>
   <div className='modal-content'>
       <div className='modal-body'>
       <div className="addlinebreak">{props.message}</div>
       </div>
       <div className='modal-footer'>
            
           <button className="button cancel" onClick={(e)=>props.setShow(false)} style={{marginRight:"1rem"}}>{props.btntext1}</button>
           { props.btntext2 &&
           <button className="button save" onClick={props.eventName}>{props.btntext2}</button>
           }
       </div>
   </div>
</div>)
}

export default React.memo(ModalPopup);
