import { Navigate, useOutlet } from "react-router-dom";
import { useAuth } from "./AuthProvider";
import Navigationbar from '../NavBar/Navigationbar';
import { Suspense } from "react";
import Loader from "../../GlobalComponent/loader";
export const ProtectedRoute = () => {
  const { user } = useAuth();
  const outlet = useOutlet();
 
  if (user===null || (user.access!==undefined && !user.access)) {
     window.localStorage.setItem("user",null);
  
    return <Navigate to="/login" />;
  }

  return (
    <div>
     <Navigationbar/>
     <Suspense fallback={<Loader/>}>
     {outlet}
    </Suspense>
    
    </div>
  );
};
