import * as React from 'react';

const SaveModal=(props)=>{

    const saveData=(e)=>{
        props.saveCustom();
        props.showSaveModal(props.message);

    }
   
     return ( <div className="modal" style={{visibility:(props.show)?"visible":"hidden"}}>
     <div className='modal-content'>
         <div className='modal-body'>
            {props.message}
         </div>
         <div className='modal-footer'>
             <button className="button cancel" onClick={(e)=>props.showSaveModal(props.message)} style={{marginRight:"1rem"}}>Cancel</button>
             <button className="button save" onClick={(e)=>saveData(e)} style={{marginRight:"1rem"}}>Overwrite</button>
    
         </div>
     </div>
 </div>)
 }

 export default React.memo(SaveModal);