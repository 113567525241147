import * as React from 'react';
import {
  Route,
  createRoutesFromElements,
  createHashRouter,
  defer
} from "react-router-dom";
import LoginPage from './GlobalComponent/LoginPage';
import Potentialfinder from './Components/PotentialFinder/newPotentailFinder';


import { ProtectedRoute } from "./GlobalComponent/Authentication/ProtectedRoute";
import './AppLayout/AppTypography.css';
import { AuthLayout } from "./GlobalComponent/Authentication/AuthLayout";



const EffectPlanning=React.lazy(()=>import("./Components/EffectPlanning/EffectPlanning"));
const TargetSteering=React.lazy(()=>import("./Components/TargetSteering/TargetStreeringNew"));
const AlertAndAnalysis=React.lazy(()=>import("./Components/AlertAnalysis/Alertanalysis"));
const UserDetail=React.lazy(()=>import("./Components/UserManagement/UserDetail"));
const UserTrack=React.lazy(()=>import("./Components/UserTracking/UserTrack"));
const UserAccessPage=React.lazy(()=>import("./Components/AccessPage/UserAccessPage"));




const getUserData = () =>
  new Promise((resolve) =>{
    setTimeout(() => {
      const user = window.localStorage.getItem("user");
      console.log("user"+user);
      resolve(user);
    }, 3000)
  });




  //console.log(process.env.REACT_APP_API_KEY)

export const router = createHashRouter(
  createRoutesFromElements(
   
    <Route
      element={<AuthLayout />}
      loader={() => defer({ userPromise: getUserData() })}
      >
     
        <Route path="" element={<LoginPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/" element={<ProtectedRoute />}>
        <Route path="finder" element={<Potentialfinder />} />
        <Route path="effect" element={<EffectPlanning/>} />
        <Route path="target" element={<TargetSteering />}/>
        <Route path="alert" element={<AlertAndAnalysis />} />
        <Route path="userManage" element={<UserDetail />} />
        <Route path="track" element={<UserTrack />} />
        <Route path="accessPage" element={<UserAccessPage />} />
       
        
      </Route>

       
    </Route>
    
   
  )
);

