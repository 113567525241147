import * as React from 'react';
import axios from "axios";
import { useForm } from "react-hook-form";
import { endpoint,key } from "../../GlobalComponent/util.js";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar"; 

const UserAccessPage=()=>{
    const [isButtonClicked,setIsButtonClicked]=React.useState(false);
   
    const [name,setName]=React.useState("");
    const [email,setEmail]=React.useState("");
    const [reason,setReason]=React.useState("");
    const [role,setRole]=React.useState("");
    const [cwid,setCwid]=React.useState("");
   
    const { register, handleSubmit, formState: { errors } } = useForm();
    

     const submitRequest=()=>{

        try{
            const getdata= async () => {
                   //  setLoader(true)   
                const response = await axios({
                    url: endpoint,
                    method: "POST",
                    headers: { "x-api-key": key},
                    data: {
                        query: `query MyQuery {
                             UserAccessRequest( Comment: "${reason}",User_Email: "${email}",User_Role:"${role}",User_CWID:"${cwid}")
                            {
                                access
                            }
                          
                            }`
                    }
                }).then(response=>{
                   if(response.data!==undefined && response.data.data!==undefined )
                    {
                      
                        setIsButtonClicked(true);
                    
                    }
                  
                })
            }
        
           getdata();
           }
           catch(error)
           {
            setIsButtonClicked(false);
               console.log(error);
              // setLoader(false);
           }
     }

    return( <>
        <AppBar position="fixed" className="navbar">
        <Container maxWidth="100%">
            <Toolbar disableGutters className="navbar">
                <Box display="flex">
                    <IconButton size="large" aria-label="logo" className="logoContainer">
                        <img src="../../AppAssets/logo.png" alt="true" className="companylogo" />
                    </IconButton>
                </Box>
                <Box className="logoContainer">
                    <p className="AppName">Inventory Steering Cockpit</p>
                </Box>
        
            </Toolbar>
        </Container>
    </AppBar> 
        
        <div className="wrapper" style={{margin:"10rem 0rem"}}>
    <div className="wrapper_inner_width">
        <div className='warning'><p>Please make sure that you are trying to access the correct Inventory Steering Cockpit page. The correct link if you already had an access is <a target='_blank' href="https://prod.cockpit.ph-ps.digital/">https://prod.cockpit.ph-ps.digital/</a>
Pages <a target='_blank' href="https://dev.cockpit.ph-ps.digital/">https://dev.cockpit.ph-ps.digital/</a> and <a target='_blank' href="https://qa.cockpit.ph-ps.digital/">https://qa.cockpit.ph-ps.digital/</a> are used for development and testing in software development lifecycle and are available for the limited number of users"</p></div>
        <div style={{display:"flex",alignItems:"center",justifyContent:"center",height:"500px"}}>
            <div style={{display:(isButtonClicked)?"none":"block"}}>

                <p style={{marginBottom:"2rem"}}>Permission is required to access the site. Please, Request for access.</p>
                <div style={{marginTop:"1rem",display:"flex",alignItems:"center",justifyContent:"center"}}>
                <form onSubmit={handleSubmit(submitRequest)}>
                  
                   <div style={{height:"8rem"}}><label>Name*: <div><input  
                        type="text"
                        {...register("name", { required: true, maxLength: 100 })}  value={name} onChange={(e) => setName(e.target.value)} /></div> </label>
                    {errors.name && <p>Please enter the Name</p>}
                    </div>
                    <div style={{height:"8rem"}}><label>CWID*: <div><input 
                        type="text"
                        {...register("cwid",{ required: true, maxLength: 10 })}  value={cwid} onChange={(e) => setCwid(e.target.value)}/></div> </label>
                    {errors.cwid && <p>Please enter the cwid</p>}
                    </div>
                    <div style={{height:"8rem"}}><label>Role*: <div><input 
                        type="text"
                        {...register("role",{ required: true, maxLength: 50 })}  value={role} onChange={(e) => setRole(e.target.value)}/></div> </label>
                    {errors.role && <p>Please enter the role</p>}
                    </div>
                    <div style={{height:"8rem"}}><label>Email*: <div><input 
                        type="text"
                        {...register("email",
                            {
                                required: true,
                                pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            })} value={email} onChange={(e) => setEmail(e.target.value)}/></div> </label>
                         {errors.email && <p>Please enter the valid email</p>}
                    </div>
                    <div style={{height:"8rem"}}><label>Reason*: <div><input 
                        type="text" autoComplete='off' placeholder='please provide your respective country code or gsm code if you are E2E Planner or Site Planner.'
                        {...register("reason",{ required: true, maxLength: 200 })}  style={{height:'8rem',width:"70rem"}} value={reason} onChange={(e) => setReason(e.target.value)}/></div> </label>
                        {errors.reason && <p>Please enter the valid reason</p>}
                        </div>

                    <button type='submit' style={{marginTop:"8rem"}} className='button save'>Request Access</button>
                </form>
                
              
                </div>
            </div>
            <div style={{display:(isButtonClicked)?"block":"none"}}>
                <p>Mail Notification has been sent to Admin for access Request</p>
            </div>
            </div>
        </div>
        </div>
        </>

    );


}

export default UserAccessPage;